import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import {
  Box,
  Button,
  Checkbox, Chip,
  colors,
  Divider,
  FormControl,
  FormControlLabel, InputLabel,
  ListItemText,
  MenuItem, OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent, Slider,
  Stack,
  Switch, TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import { ALL_ML_SOURCE_TYPES } from 'src/entities/admin/companyEntity';
import { RespectType, WeekDayEn } from 'src/entities/planningEntity';
import { HowToPlanning, PlanningConditions, PlanningPriority } from 'src/entities/PlanningHistory.entity';
import { RelaxedRuleVo } from 'src/vo/RelaxedRule.vo';

type Props = {
  updateBalancedLoading: (balancedLoading: boolean) => void;
  updateIsRespectRecent: (bool: boolean) => void;
  updateExcludeToll: (bool: boolean) => void;
  updateRespectType: (respectType: RespectType) => void;
  updateRespectDaysOfWeek: (days: WeekDayEn[]) => void;
  updateRespectOn: (on: string) => void;
  updateIsTimeTableBackward: (bool: boolean) => void;
  updateIncludeFutureDeliveries: (bool: boolean) => void;
  updateAutoSplitOrders: (bool: boolean) => void;
  updateDontExchangePreroute: (bool: boolean) => void;
  updateConcurrentAllOrNothing: (bool: boolean) => void;
  updateExpandLoadStartMinutes: (minutes: number) => void;
  updateExpandLoadEndMinutes: (minutes: number) => void;
  updateExpandUnloadStartMinutes: (minutes: number) => void;
  updateExpandUnloadEndMinutes: (minutes: number) => void;
  updateExpandMaxVolumeRate: (rate: number) => void;
  updateExpandMaxLoadCapacityRate: (rate: number) => void;
  planningRequest: (shiftIds: number[], orderIds: number[]) => void;
  onClose: () => void;
  selectedDeliveryIds: number[];
  selectedOrderIds: number[];
  startOn: string;
  relaxedRules: RelaxedRuleVo[];
  selectedRelaxedRules: RelaxedRuleVo[];
  setSelectedRelaxedRules: (value: RelaxedRuleVo[]) => void;
  setPriorityLargeTrucks: (priority: number) => void;
  priorityLargeTrucks: number;
  mlSourceTypes: number[];
  updateMLSourceType: (MLSourceType: number) => void;
}

const PlanningPresenter: FC<Props> = memo((
  {
    updateRespectType,
    updateRespectDaysOfWeek,
    updateRespectOn,
    updateBalancedLoading,
    updateIsRespectRecent,
    updateExcludeToll,
    updateIsTimeTableBackward,
    updateIncludeFutureDeliveries,
    updateAutoSplitOrders,
    updateDontExchangePreroute,
    updateConcurrentAllOrNothing,
    updateExpandLoadStartMinutes,
    updateExpandLoadEndMinutes,
    updateExpandUnloadStartMinutes,
    updateExpandUnloadEndMinutes,
    updateExpandMaxVolumeRate,
    updateExpandMaxLoadCapacityRate,
    planningRequest,
    onClose,
    selectedDeliveryIds,
    selectedOrderIds,
    startOn,
    relaxedRules,
    selectedRelaxedRules,
    setSelectedRelaxedRules,
    setPriorityLargeTrucks,
    priorityLargeTrucks,
    mlSourceTypes,
    updateMLSourceType,
  }
) => {
  const licenseContext = useContext(LicenseContext);

  const [howToPlanning, setHowToPlanning] = useState<HowToPlanning>('最適化');
  const [planningPriority, setPlanningPriority] = useState<PlanningPriority>('指定なし');
  const [isForcePlanning, setIsForcePlanning] = useState<boolean>(false);
  const [ignoreWeightConstraints, setIgnoreWeightConstraints] = useState<boolean>(false);
  const [ignoreTimeConstraints, setIgnoreTimeConstraints] = useState<boolean>(false);
  const [respectRecentPlan, setRespectRecentPlan] = useState<boolean>(false);
  const [availabilityOfExpressway, setAvailabilityOfExpressway] = useState<boolean>(false);
  const [respectType, setRespectType] = useState<RespectType>('day_of_week');
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<WeekDayEn[]>([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]);
  const [respectOn, setRespectOn] = useState<string>(startOn);
  const [isTimeTableBackward, setIsTimeTableBackward] = useState<boolean>(false);
  const [includeFutureDeliveries, setIncludeFutureDeliveries] = useState<boolean>(false);
  const [autoSplitOrders, setAutoSplitOrders] = useState<boolean>(false);
  const [dontExchangePreroute, setDontExchangePreroute] = useState<boolean>(true);
  const [concurrentAllOrNothing, setConcurrentAllOrNothing] = useState<boolean>(true);
  const [expandLoadStartMinutes, setExpandLoadStartMinutes] = useState<number>(0);
  const [expandLoadEndMinutes, setExpandLoadEndMinutes] = useState<number>(0);
  const [expandUnloadStartMinutes, setExpandUnloadStartMinutes] = useState<number>(0);
  const [expandUnloadEndMinutes, setExpandUnloadEndMinutes] = useState<number>(0);
  const [expandMaxVolumeRate, setExpandMaxVolumeRate] = useState<number>(0);
  const [expandMaxLoadCapacityRate, setExpandMaxLoadCapacityRate] = useState<number>(0);
  const [mlSourceType, setMLSourceType] = useState<number>(-1);

  const handleSelectedRelaxRules = (event: SelectChangeEvent<typeof selectedRelaxedRules>) => {
    const {
      target: { value },
    } = event;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setSelectedRelaxedRules(typeof value === 'string' ? value.split(',') : value);
  };
  const selectRelaxRuleButtonOnClick = () => {
    const values = relaxedRules.some((it) => selectedRelaxedRules.includes(it)) ? [] : relaxedRules;

    setSelectedRelaxedRules(values);
  };

  const howToPlanningOnChange = (event: SelectChangeEvent) => {
    setHowToPlanning(event.target.value as HowToPlanning);
  };

  const planningPriorityOnChange = (event: SelectChangeEvent) => {
    setPlanningPriority(event.target.value as PlanningPriority);
  };

  const availabilityOfExpresswayOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAvailabilityOfExpressway(event.target.checked);
  };

  const includeFutureDeliveriesOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIncludeFutureDeliveries(event.target.checked);
  };

  const splitOrdersOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoSplitOrders(event.target.checked);
  };

  const dontExchangePrerouteOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDontExchangePreroute(event.target.checked);
  };

  const concurrentAllOrNothingOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConcurrentAllOrNothing(event.target.checked);
  };

  const respectRecentPlanOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRespectRecentPlan(event.target.checked);
  };

  const respectTypeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRespectType(event.target.value as RespectType);
  };

  const specificDayOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRespectOn(event.target.value);
  };

  const mlSourceTypeChange = (event: SelectChangeEvent) => {
    setMLSourceType(Number(event.target.value));
  };

  const onClick = () => {
    ReactGA.event('executePlanning', { screen_name: SCREEN_NAMES.PLANNING, button_name: '自動配車' });
    onClose();

    planningRequest(
      selectedDeliveryIds,
      selectedOrderIds,
    );
  };

  useEffect(() => {
    updateExpandLoadStartMinutes(expandLoadStartMinutes);
  }, [expandLoadStartMinutes]);

  useEffect(() => {
    updateExpandLoadEndMinutes(expandLoadEndMinutes);
  }, [expandLoadEndMinutes]);

  useEffect(() => {
    updateExpandUnloadStartMinutes(expandUnloadStartMinutes);
  }, [expandUnloadStartMinutes]);

  useEffect(() => {
    updateExpandUnloadEndMinutes(expandUnloadEndMinutes);
  }, [expandUnloadEndMinutes]);

  useEffect(() => {
    updateExpandMaxVolumeRate(expandMaxVolumeRate);
  }, [expandMaxVolumeRate]);

  useEffect(() => {
    updateExpandMaxLoadCapacityRate(expandMaxLoadCapacityRate);
  }, [expandMaxLoadCapacityRate]);

  useEffect(() => {
    updateIsRespectRecent(respectRecentPlan);
  }, [respectRecentPlan]);

  useEffect(() => {
    updateExcludeToll(!availabilityOfExpressway);
  }, [availabilityOfExpressway]);

  useEffect(() => {
    updateRespectType(respectType);
  }, [respectType]);

  useEffect(() => {
    updateRespectDaysOfWeek(selectedDaysOfWeek);
  }, [selectedDaysOfWeek]);

  useEffect(() => {
    updateRespectOn(respectOn);
  }, [respectOn]);

  useEffect(() => {
    updateIsTimeTableBackward(isTimeTableBackward);
  }, [isTimeTableBackward]);

  useEffect(() => {
    updateIncludeFutureDeliveries(includeFutureDeliveries);
  }, [includeFutureDeliveries]);

  useEffect(() => {
    updateAutoSplitOrders(autoSplitOrders);
  }, [autoSplitOrders, updateAutoSplitOrders]);

  useEffect(() => {
    updateDontExchangePreroute(dontExchangePreroute);
  }, [dontExchangePreroute, updateDontExchangePreroute]);

  useEffect(() => {
    updateConcurrentAllOrNothing(concurrentAllOrNothing);
  }, [concurrentAllOrNothing, updateConcurrentAllOrNothing]);

  useEffect(() => {
    updateMLSourceType(mlSourceType);
  }, [mlSourceType]);

  useEffect(() => {
    if (howToPlanning === '平準化') {
      updateBalancedLoading(true);
    } else {
      updateBalancedLoading(false);
    }
  }, [howToPlanning, updateBalancedLoading]);

  useEffect(() => {
    const json = localStorage.getItem('PlanningPresenterSettings');

    if (!json) {
      setAvailabilityOfExpressway(licenseContext.config?.availability_of_expressway);
      return;
    }

    const planning = JSON.parse(json) as unknown as PlanningConditions;

    setHowToPlanning(
      ['最適化', '平準化'].includes(planning.howToPlanning) ? planning.howToPlanning : '最適化'
    );
    setPlanningPriority(
      planning.planningPriority
    );
    setIsForcePlanning(
      planning.isForcePlanning
    );
    setIgnoreWeightConstraints(
      planning.ignoreWeightConstraints
    );
    setIgnoreTimeConstraints(
      planning.ignoreTimeConstraints
    );
    setRespectRecentPlan(
      planning.respectRecentPlan
    );
    setAvailabilityOfExpressway(
      planning.availabilityOfExpressway
    );
    setRespectType(
      planning.respectType
    );
    setSelectedDaysOfWeek(
      planning.selectedDaysOfWeek
    );
    setRespectOn(
      planning.respectOn
    );
    setIsTimeTableBackward(
      planning.isTimeTableBackward
    );
    setIncludeFutureDeliveries(
      planning.includeFutureDeliveries
    );
    setAutoSplitOrders(
      false
    );
    if (selectedOrderIds.length === 0) {
      setDontExchangePreroute(
        false
      );
    } else {
      setDontExchangePreroute(
        planning.dontExchangePreroute === undefined ? true : planning.dontExchangePreroute,
      );
    }
    setConcurrentAllOrNothing(
      planning.concurrentAllOrNothing === undefined ? licenseContext?.config?.concurrent_all_or_nothing : planning.concurrentAllOrNothing
    );
    setSelectedRelaxedRules(
      planning.selectedRelaxedRules
    );
    setPriorityLargeTrucks(
      planning.priorityLargeTrucks
    );
    setExpandLoadStartMinutes(
      planning.expandLoadStartMinutes
    );
    setExpandLoadEndMinutes(
      planning.expandLoadEndMinutes
    );
    setExpandUnloadStartMinutes(
      planning.expandUnloadStartMinutes
    );
    setExpandUnloadEndMinutes(
      planning.expandUnloadEndMinutes
    );
    setExpandMaxVolumeRate(
      planning.expandMaxVolumeRate
    );
    setExpandMaxLoadCapacityRate(
      planning.expandMaxLoadCapacityRate
    );
  }, [licenseContext.config, selectedOrderIds, setPriorityLargeTrucks, setSelectedRelaxedRules]);

  useEffect(() => {
    const obj = {
      howToPlanning,
      planningPriority,
      isForcePlanning,
      ignoreWeightConstraints,
      ignoreTimeConstraints,
      respectRecentPlan,
      availabilityOfExpressway,
      respectType,
      selectedDaysOfWeek,
      respectOn,
      isTimeTableBackward,
      includeFutureDeliveries,
      splitOrders: autoSplitOrders,
      selectedRelaxedRules,
      priorityLargeTrucks,
      expandLoadStartMinutes,
      expandLoadEndMinutes,
      expandUnloadStartMinutes,
      expandUnloadEndMinutes,
      expandMaxVolumeRate,
      expandMaxLoadCapacityRate,
      dontExchangePreroute,
      concurrentAllOrNothing,
    };

    localStorage.setItem('PlanningPresenterSettings', JSON.stringify(obj));
  }, [
    howToPlanning,
    planningPriority,
    isForcePlanning,
    ignoreWeightConstraints,
    ignoreTimeConstraints,
    respectRecentPlan,
    availabilityOfExpressway,
    respectType,
    selectedDaysOfWeek,
    respectOn,
    isTimeTableBackward,
    includeFutureDeliveries,
    autoSplitOrders,
    selectedRelaxedRules,
    priorityLargeTrucks,
    expandLoadStartMinutes,
    expandLoadEndMinutes,
    expandUnloadStartMinutes,
    expandUnloadEndMinutes,
    expandMaxVolumeRate,
    expandMaxLoadCapacityRate,
    dontExchangePreroute,
    concurrentAllOrNothing,
  ]);

  useEffect(() => {
    if (!selectedRelaxedRules) return;
    if (!selectedRelaxedRules.length) return;

    if (selectedRelaxedRules.includes('指定時間')) {
      setExpandLoadStartMinutes(0);
      setExpandLoadEndMinutes(0);
      setExpandUnloadStartMinutes(0);
      setExpandUnloadEndMinutes(0);
    }

    if (selectedRelaxedRules.includes('重量')) {
      setExpandMaxLoadCapacityRate(0);
    }

    if (selectedRelaxedRules.includes('体積')) {
      setExpandMaxVolumeRate(0);
    }
  }, [selectedRelaxedRules]);

  return (
    <Stack
      sx={{
        p: 2
      }} spacing={1}
      divider={<Divider />}
    >
      <Stack spacing={2}>
        <Stack>
          <Typography
            variant="h5"
            gutterBottom
          >
            計算方法を選択してください
          </Typography>
          <FormControl
            fullWidth
            variant="standard"
          >
            <Select
              value={howToPlanning}
              onChange={howToPlanningOnChange}
              MenuProps={{
                PaperProps: {
                  sx: {
                    p: 0,
                    m: 0,
                  },
                },
              }}
            >
              <MenuItem value="最適化" sx={{ m: 0 }}>最適化 使用するトラック台数と時間距離を最小化します</MenuItem>
              <MenuItem value="平準化" sx={{ m: 0 }}>平準化 割り付ける荷物を均等化します</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="column"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={3}
          >
            <FormControl>
              <RadioGroup
                row
                value={planningPriority}
                onChange={planningPriorityOnChange}
              >
                <FormControlLabel value="指定なし" control={<Radio />} label="指定なし" />
                <FormControlLabel value="近隣優先" control={<Radio />} label="近隣優先" />
                <FormControlLabel value="遠方優先" control={<Radio />} label="遠方優先" />
              </RadioGroup>
            </FormControl>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={0.5}
              flexGrow={1}
            >
              <Stack
                minWidth="4rem"
              >
                <Typography>
                  小型優先
                </Typography>
              </Stack>
              <Slider
                value={priorityLargeTrucks * 100}
                onChange={(_, value) => setPriorityLargeTrucks(value as number / 100)}
                getAriaValueText={(value) => `${value * 100}%`}
                step={10}
                min={0}
                max={100}
                valueLabelDisplay="auto"
              />
              <Stack
                minWidth="4rem"
              >
                <Typography>
                  大型優先
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <FormControlLabel
                onChange={availabilityOfExpresswayOnChange}
                control={<Switch checked={availabilityOfExpressway} />}
                label="有料道路使用"
              />
            </Stack>
            <Stack>
              <FormControlLabel
                onChange={includeFutureDeliveriesOnChange}
                control={<Switch checked={includeFutureDeliveries} />}
                label="宵積み有効"
              />
            </Stack>
          </Stack>
          {/* <Stack>
            <FormControlLabel
              onChange={splitOrdersOnChange}
              control={<Checkbox checked={autoSplitOrders} />}
              label="案件の重量・体積が、選択したトラックの最大積載量を超える場合に案件を自動で分割する"
            />
          </Stack> */}
          <Stack direction="row" gap={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <FormControlLabel
                onChange={dontExchangePrerouteOnChange}
                control={<Switch checked={dontExchangePreroute} />}
                label="現在の配車を維持して追加配車"
              />
              <Tooltip arrow title="新しいオーダーを追加配車する際、既存のドライバー割り当てを維持するか、ドライバー間で割り当てを変更するかを選択できます。">
                <InfoOutlinedIcon sx={{ color: colors.blue[900] }} />
              </Tooltip>
            </Stack>
            <Stack>
              <FormControlLabel
                onChange={concurrentAllOrNothingOnChange}
                control={<Switch checked={concurrentAllOrNothing} />}
                label="同一ロケーションで複数台になってしまう場合は割り当てない"
              />
            </Stack>
          </Stack>
        </Stack>

        {licenseContext.config.use_ai_planning && (
          <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={2}>
              <FormControlLabel
                onChange={respectRecentPlanOnChange}
                control={<Switch checked={respectRecentPlan} />}
                label="過去の計画を考慮して配車する"
                sx={{ whiteSpace: 'nowrap' }}
              />
              {mlSourceTypes.length > 0 && (
                <Stack gap={1} direction="row" alignItems="center" sx={{ width: '100%' }}>
                  <Stack sx={{ whiteSpace: 'nowrap' }}>学習対象データを選択</Stack>
                  <Stack sx={{ width: '100%' }}>
                    <Select
                      value={String(mlSourceType)}
                      onChange={mlSourceTypeChange}
                      disabled={!respectRecentPlan}
                      variant="standard"
                      fullWidth
                    >
                      <MenuItem value="-1">デフォルト設定</MenuItem>
                      {mlSourceTypes.map((it) => (
                        <MenuItem value={it}>{ALL_ML_SOURCE_TYPES[it]}</MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack>
              <FormControl>
                <RadioGroup onChange={respectTypeOnChange} value={respectType}>
                  <Stack direction="row" spacing={2}>
                    <FormControlLabel value="day_of_week" label="日付指定なし" control={<Radio />} disabled={!respectRecentPlan} />
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={2}
                    >
                      <FormControlLabel
                        value="specific_day"
                        label="日付指定"
                        control={
                          <Radio />
                        }
                        disabled={!respectRecentPlan}
                      />
                      <TextField
                        type="date"
                        size="small"
                        value={respectOn}
                        onChange={specificDayOnChange}
                        disabled={
                          !respectRecentPlan || respectType !== 'specific_day'
                        }
                      />
                    </Stack>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Stack>
          <Typography>
            制約緩和
          </Typography>
          <Stack
            spacing={0.5}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  集荷開始
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={120}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandLoadStartMinutes}
                  onChange={(_, value) => setExpandLoadStartMinutes(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 120,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandLoadStartMinutes}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandLoadStartMinutes(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  分前まで緩和
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  集荷締切
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={120}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandLoadEndMinutes}
                  onChange={(_, value) => setExpandLoadEndMinutes(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 120,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandLoadEndMinutes}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandLoadEndMinutes(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  分後まで緩和
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  納品開始
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={120}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandUnloadStartMinutes}
                  onChange={(_, value) => setExpandUnloadStartMinutes(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 120,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandUnloadStartMinutes}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandUnloadStartMinutes(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  分前まで緩和
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  納品締切
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={120}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandUnloadEndMinutes}
                  onChange={(_, value) => setExpandUnloadEndMinutes(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 120,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('指定時間')}
                  value={expandUnloadEndMinutes}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandUnloadEndMinutes(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  分後まで緩和
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  積載重量
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={100}
                  disabled={selectedRelaxedRules.includes('重量')}
                  value={expandMaxLoadCapacityRate}
                  onChange={(_, value) => setExpandMaxLoadCapacityRate(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('重量')}
                  value={expandMaxLoadCapacityRate}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandMaxLoadCapacityRate(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  ％まで緩和
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                flexGrow={1}
              >
                <Stack
                  sx={{
                    minWidth: '4rem'
                  }}
                >
                  積載体積
                </Stack>
                <Slider
                  step={10}
                  min={0}
                  max={100}
                  disabled={selectedRelaxedRules.includes('体積')}
                  value={expandMaxVolumeRate}
                  onChange={(_, value) => setExpandMaxVolumeRate(value as number)}
                />
                <TextField
                  size="small"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: 'numeric',
                    inputMode: 'numeric',
                  }}
                  sx={{
                    width: '10rem'
                  }}
                  disabled={selectedRelaxedRules.includes('体積')}
                  value={expandMaxVolumeRate}
                  onChange={(event) => {
                    const maybe = Number(event.target.value);
                    if (Number.isNaN(maybe)) return;

                    setExpandMaxVolumeRate(Number(event.target.value));
                  }}
                />
                <Stack
                  sx={{
                    width: '12rem'
                  }}
                >
                  ％まで緩和
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Stack
            direction="row"
          >
            <Button
              onClick={selectRelaxRuleButtonOnClick}
              size="small"
            >
              {
                selectedRelaxedRules.length ? '選択した制約無効をリセットする' : 'すべての制約を無効にする'
              }
            </Button>
          </Stack>
          <FormControl>
            <InputLabel>
              無効にする制約
            </InputLabel>
            <Select
              multiple
              value={selectedRelaxedRules}
              onChange={handleSelectedRelaxRules}
              input={<OutlinedInput label="無効にする制約" />}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}
                >
                  {
                    selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                      />
                    ))
                  }
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    p: 0,
                    m: 0,
                    maxHeight: 600,
                  },
                },
              }}
            >
              {
                relaxedRules.map((it) => (
                  <MenuItem key={it} value={it}>
                    <Checkbox checked={selectedRelaxedRules.indexOf(it) > -1} />
                    <ListItemText primary={it} />
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack>
        <Button
          size="large"
          variant="contained"
          startIcon={<PsychologyRoundedIcon />}
          onClick={onClick}
        >
          実行する
        </Button>
      </Stack>
    </Stack>
  );
});

export default PlanningPresenter;
