/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Dialog, DialogContent,
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridColumnVisibilityModel, jaJP } from '@mui/x-data-grid';
import {
  DataGridPremium, GridColumnResizeParams,
  GridFilterItem,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import OrderForm from 'src/components/V2OrderForm';
import OrderSplitForm from 'src/components/V2OrderSplitForm';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { OrderResponseEntity } from 'src/entities/Order.response.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { OrderSplitEntity } from 'src/entities/orderSplitEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useDeleteAllOrdersMutation } from 'src/hooks/useDeleteAllOrders.mutation';
import { useOrdersRequest as usePaginationRequest, queryKey } from 'src/hooks/useOrders.request';
import { useMutationOrder } from 'src/hooks/useQueryOrders';
import arrayUtil from 'src/utils/array.util';
import { dataGridUtil } from 'src/utils/dataGrid.util';
import numberUtil from 'src/utils/number.util';
import operatorValueUtil from 'src/utils/operatorValue.util';
import { orderRequestUtil } from 'src/utils/orderRequest.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';
import CoordinationEditDialog from '../V2PlacesPresenter/PositionEditDialog';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';

type Props = {
  startDate: Date;
  endDate: Date;
}

const V2OrdersPresenter: FC<Props> = memo(({
  startDate,
  endDate,
}) => {
  const localStorageItemPrefix = 'order-columns';
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const defaultRowHeight = 52;
  const queryClient = useQueryClient();

  const DEFAULT_HIDDEN_COLUMNS = useMemo(() => ({
    loading_latitude: false,
    loading_longitude: false,
    unloading_latitude: false,
    unloading_longitude: false,
  }), []);

  const [startOn, setStartOn] = useState<Date | undefined>(undefined);
  const [endOn, setEndOn] = useState<Date | undefined>(undefined);
  const [startOnStr, setStartOnStr] = useState('');
  const [endOnStr, setEndOnStr] = useState('');
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [isLoading, setIsLoading]: [boolean, ((value: (((prevState: boolean) => boolean) | boolean)) => void)] = useState<boolean>(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(defaultRowHeight);
  const [orderSplitEntity, setOrderSplitEntity] = useState<OrderSplitEntity | undefined>(undefined);
  const [splitDialogIsOpen, setSplitDialogIsOpen] = useState<boolean>(false);
  const [editTargetOrder, setEditTargetOrder] = useState<OrderEntity>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);
  const apiRef = useGridApiRef();

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition, startOn, endOn);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, startOn, endOn, Number.MAX_SAFE_INTEGER);
  const { updateOrder, deleteOrders, operationBulkResetting } = useMutationOrder();
  const { post } = useDeleteAllOrdersMutation();

  const deleteAll = (settledFnk: () => void) => {
    if (startOn === undefined || endOn === undefined) return;

    if (window.confirm(`選択された ${rowCount} 件の注文を削除します。よろしいですか？`)) {
      setIsLoading(true);
      setSelectedOrderIds([]);

      post.mutate(({ startOn, endOn }), {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const handleDateRange = (range: [Date, Date]) => {
    const start = range[0];
    const end = range[1] || start;
    const diffDays = differenceInDays(end, startOn);

    if (diffDays < 0) {
      enqueueSnackbar('終了日は開始日よりも過去に設定できません');
      return;
    }

    if (diffDays > 30) {
      enqueueSnackbar('最大取得可能日数の31日を超過しています');
      return;
    }

    setStartOn(start);
    setEndOn(end);
  };

  useEffect(() => {
    if (startOn) {
      const d = datetimeDecorator.toYyyyMmDd(startOn);
      setStartOnStr((prev) => (prev === d ? prev : d));
    }
    if (endOn) {
      const d = datetimeDecorator.toYyyyMmDd(endOn);
      setEndOnStr((prev) => (prev === d ? prev : d));
    }
  }, [startOn, endOn]);

  useEffect(() => {
    if (startOnStr && endOnStr) {
      navigate(`/orders/${startOnStr}/${endOnStr}`);
    }
  }, [navigate, startOnStr, endOnStr]);

  const handleStartOn = (day: Date) => {
    setStartOn(day);
    setEndOn(day);
  };

  const handleEndOn = (day: Date) => {
    const diffDays = differenceInDays(day, startOn);

    if (diffDays < 0) {
      enqueueSnackbar('終了日は開始日よりも過去に設定できません');
      return;
    }

    if (diffDays > 30) {
      enqueueSnackbar('最大取得可能日数の31日を超過しています');
      return;
    }

    setEndOn(day);
  };

  const toggleDialogIsOpen = () => {
    const maybeEditEntity: OrderResponseEntity | undefined = requestData?.data && requestData.data.find((order) => order.id === selectedOrderIds.slice(-1)[0]);

    if (maybeEditEntity) {
      setEditTargetOrder(orderRequestUtil.convertToOrderEntity(maybeEditEntity));
    } else {
      setEditTargetOrder(null);
    }

    setDialogIsOpen(!dialogIsOpen);
  };
  const dialogOnClose = () => {
    setDialogIsOpen(false);
  };

  const dataGridOnColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
    const requestPath = '/api/v2/company';
    if (Object.keys(newModel).length === 0) {
      // 「すべて表示」を選択した場合は空になる。緯度経度カラムを表示にしておかないと、デフォルトで非表示になってしまう。
      Object.keys(DEFAULT_HIDDEN_COLUMNS).forEach((it) => {
        newModel[it] = true;
      });
    }
    const requestBody = {
      order_column_visibilities: newModel
    };
    axios.post(requestPath, requestBody).then(() => {
      setColumnVisibilityModel(newModel);
    }).catch((err) => {
      enqueueSnackbar('エラーが発生しました');
      throw err;
    });
  };

  const [orderEditEntity, setOrderEditEntity] = useState<OrderResponseEntity>(null);
  const [loadingCoordinationEditDialogIsOpen, setLoadingCoordinationEditDialogIsOpen] = useState(false);
  const [unloadingCoordinationEditDialogIsOpen, setUnloadingCoordinationEditDialogIsOpen] = useState(false);

  const closeLoadingCoordinationEditDialog = () => {
    setOrderEditEntity(null);
    setLoadingCoordinationEditDialogIsOpen(!loadingCoordinationEditDialogIsOpen);
  };

  const closeUnloadingCoordinationEditDialog = () => {
    setOrderEditEntity(null);
    setUnloadingCoordinationEditDialogIsOpen(!unloadingCoordinationEditDialogIsOpen);
  };

  const openLoadingMapDialog = (entity: OrderResponseEntity) => {
    console.log('openLoadingMapDialog');
    setOrderEditEntity(entity);
    setLoadingCoordinationEditDialogIsOpen(true);
  };

  const openUnloadingMapDialog = (entity: OrderResponseEntity) => {
    console.log('openUnloadingMapDialog');
    setOrderEditEntity(entity);
    setUnloadingCoordinationEditDialogIsOpen(true);
  };

  const updateLoadingLatLng = (lat: string, lng: string) => {
    const orderEntity = orderRequestUtil.convertToOrderEntity(orderEditEntity);
    orderEntity.loading_latitude = lat;
    orderEntity.loading_longitude = lng;
    orderEntity.update_position = true;

    setIsLoading(true);
    updateOrder.mutate(orderEntity, {
      onSuccess: () => { closeLoadingCoordinationEditDialog(); },
      onError: (e: AxiosError<{ data: string[]; }>) => { enqueueSnackbar(e.response.data.data.join(', ')); },
      onSettled: () => {
        queryClient.invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const loadingCoordinationEditDialog = (
    orderEditEntity && loadingCoordinationEditDialogIsOpen && (
      <CoordinationEditDialog
        key="loadingCoordinationEditDialog"
        dialogIsOpen={loadingCoordinationEditDialogIsOpen}
        closeDialog={closeLoadingCoordinationEditDialog}
        loading={isLoading}
        lat={orderEditEntity.loading_latitude}
        lng={orderEditEntity.loading_longitude}
        updateLatLng={updateLoadingLatLng}
        isOrder
      />
    )
  );

  const updateUnloadingLatLng = (lat: string, lng: string) => {
    const orderEntity = orderRequestUtil.convertToOrderEntity(orderEditEntity);
    orderEntity.unloading_latitude = lat;
    orderEntity.unloading_longitude = lng;
    orderEntity.update_position = true;

    setIsLoading(true);
    updateOrder.mutate(orderEntity, {
      onSuccess: () => { closeLoadingCoordinationEditDialog(); },
      onError: (e: AxiosError<{ data: string[]; }>) => { enqueueSnackbar(e.response.data.data.join(', ')); },
      onSettled: () => {
        queryClient.invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const unloadingCoordinationEditDialog = (
    orderEditEntity && unloadingCoordinationEditDialogIsOpen && (
      <CoordinationEditDialog
        key="unloadingCoordinationEditDialog"
        dialogIsOpen={unloadingCoordinationEditDialogIsOpen}
        closeDialog={closeUnloadingCoordinationEditDialog}
        loading={isLoading}
        lat={orderEditEntity.unloading_latitude}
        lng={orderEditEntity.unloading_longitude}
        updateLatLng={updateUnloadingLatLng}
        isOrder
      />
    )
  );

  const dataGridColumns: GridColDef[] = Columns(openLoadingMapDialog, openUnloadingMapDialog).filter((col) => {
    if (!licenseContext.config) return true;
    if (licenseContext.config.selectable_companies?.length > 1) return true;

    return col.field !== 'company_name';
  });

  const columnsWithCustomFields = useMemo(() => {
    const customInputFields = licenseContext?.config?.custom_input_fields || [];

    const customFields = customInputFields.map((field, index) => (
      {
        field: `custom_input_fields_${index}`,
        headerName: field,
        width: 200,
        valueGetter: (params: { row: OrderEntity }) => params
          .row
          .custom_input_fields
          ?.find((it) => it.key === field)?.value
      }
    ));

    return [
      ...dataGridColumns,
      ...customFields
    ];
  }, [dataGridColumns, licenseContext]);

  const dataGridOnSelectionModelChange = (orderIds: number[]) => {
    if (orderIds.length === requestData.totalCount) {
      setSelectedOrderIds(orderIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedOrderIds(orderIds.filter((id) => set.has(id)));
    }
  };

  const removeSelectedOrders = async () => {
    if (!selectedOrderIds.length) return;

    // eslint-disable-next-line no-alert
    if (!window.confirm(`${selectedOrderIds.length}件の案件を削除します。よろしいですか？`)) return;

    setSelectedOrderIds([]);
    setIsLoading(true);

    try {
      await deleteOrders.mutateAsync(selectedOrderIds);
      enqueueSnackbar(`${selectedOrderIds.length}件の注文を削除しました`);
    } catch (e) {
      enqueueSnackbar('エラーが発生しました');
      throw e;
    } finally {
      queryClient
        .invalidateQueries([queryKey])
        .finally(() => {
          setIsLoading(false);
          setSelectedOrderIds([]);
        });
    }
  };

  const resetSelectedOrders = async () => {
    if (!selectedOrderIds.length) return;

    setIsLoading(true);

    try {
      await operationBulkResetting.mutateAsync(selectedOrderIds);
      enqueueSnackbar(`${selectedOrderIds.length}件の注文を割当解除しました`);
    } catch (e) {
      enqueueSnackbar('エラーが発生しました');
      throw e;
    } finally {
      queryClient
        .invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
          setSelectedOrderIds([]);
        });
    }
  };

  const toggleSplitDialogIsOpen = () => {
    setSplitDialogIsOpen(!splitDialogIsOpen);
  };

  const splitDialogOnClose = () => {
    setSplitDialogIsOpen(false);
  };

  const onClickSplit = () => {
    toggleSplitDialogIsOpen();
  };

  const onSplitDialogSubmit = () => {
    dialogOnClose();
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    const r = params.row as unknown as OrderResponseEntity;

    setEditTargetOrder(
      orderRequestUtil.convertToOrderEntity(r)
    );
    setDialogIsOpen(true);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const convertedItems = items.map((it) => {
      if (it.columnField === 'loading_staying_minutes' || it.columnField === 'unloading_staying_minutes') {
        const columnField = it.columnField.replace('minutes', 'seconds');
        if (Number(it.value)) {
          return { ...it, columnField, value: Number(it.value) * 60 };
        }
        if (it.operatorValue === 'isNotEmpty') {
          return { ...it, columnField, operatorValue: 'isNotNull' };
        }
        return { ...it, columnField };
      }
      if (it.columnField === 'item_total_weight_kg') {
        const columnField = 'item_total_weight_for_calculation';
        if (Number(it.value)) {
          return { ...it, columnField, value: Number(it.value) * 1000 };
        }
        if (it.operatorValue === 'isNotEmpty') {
          return { ...it, columnField, operatorValue: 'isNotNull' };
        }
        return { ...it, columnField };
      }
      if (['charge_basic_fee_yen', 'charge_highway_fee_yen', 'charge_loading_fee_yen',
           'charge_ancillary_fee_yen', 'charge_waiting_time_fee_yen', 'charge_unloading_fee_yen',
           'charge_expenses_fee_yen'].includes(it.columnField)) {
        if (it.operatorValue === 'isNotEmpty') {
          return { ...it, operatorValue: 'isNotNull' };
        }
        return it;
      }
      return it;
    });

    const ransackPredicates: RansackPredicateEntity[] = convertedItems.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate.link === 'and'));
  });

  useEffect(() => {
    setStartOn(startDate);
    setEndOn(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    const lastSelectedEntity = requestData?.data && requestData.data.find((order) => order.id === selectedOrderIds.slice(-1)[0]);
    if (!lastSelectedEntity) return;

    setOrderSplitEntity({
      order_id: lastSelectedEntity.id,
      original_item_count: lastSelectedEntity.item_count,
      original_item_total_weight_kg: numberUtil.convertFromGramToKg(lastSelectedEntity.item_total_weight_for_calculation),
      original_item_total_volume_m3: numberUtil.convertFromMm3ToM3(lastSelectedEntity.item_total_volume_mm3),
      is_item_total_volume_m3_required: !!lastSelectedEntity.item_total_volume_mm3
    });
  }, [requestData, selectedOrderIds]);

  useEffect(() => {
    const isMemoHide = columnVisibilityModel.memo === false;

    const resetRowHeight = () => {
      setRowHeight(defaultRowHeight);
    };

    if (!requestData?.data || isMemoHide) {
      resetRowHeight();
      return;
    }

    const maxMemoNumberOfLines = Math.max(...(requestData?.data || []).map((entity) => (entity.memo
      ? entity.memo
        .trim()
        .split('\n')
        .flatMap((str) => arrayUtil.sliceByNumber(str.split(''), 20)).length
      : 0)));
    const defaultFontSize = 24;

    if (maxMemoNumberOfLines <= 2) {
      resetRowHeight();
      return;
    }

    setRowHeight(maxMemoNumberOfLines * defaultFontSize);
  }, [requestData, columnVisibilityModel]);

  useEffect(() => {
    if (!licenseContext.config) return;

    const visibilityModel = licenseContext.config?.order_column_visibilities || DEFAULT_HIDDEN_COLUMNS;
    Object.keys(DEFAULT_HIDDEN_COLUMNS).forEach((it) => {
      if (!(it in visibilityModel)) {
        visibilityModel[it] = false;
      }
    });
    if (visibilityModel) {
      setColumnVisibilityModel(visibilityModel);
    }
  }, [DEFAULT_HIDDEN_COLUMNS, licenseContext.config]);

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 1);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = selectedOrderIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [selectedOrderIds, pageSize, rowCount]);

  const handleDownloadExcel = async () => {
    const options = {
      fileName: `LOG-案件一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };
    apiRef.current.setRows(allRequestData.data);
    await apiRef.current.exportDataAsExcel(options);
    apiRef.current.setRows(requestData.data);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedOrderIds([]);
  };

  useEffect(() => {
    localStorage.setItem('planningOn', startOnStr);
  }, [startOnStr]);

  return (
    <>
      <Helmet>
        <title>案件一覧</title>
      </Helmet>
      <Box p={2}>
        {loadingCoordinationEditDialog}
        {unloadingCoordinationEditDialog}
        {
          dialogIsOpen && (
            <OrderForm
              dialogIsOpen={dialogIsOpen}
              dialogOnClose={dialogOnClose}
              onClose={dialogOnClose}
              onClickSplit={onClickSplit}
              startOn={format(startOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}
              entity={
                editTargetOrder
              }
            />
          )
        }
        {
          splitDialogIsOpen && (
            <Dialog
              open={splitDialogIsOpen}
              maxWidth="md"
              fullWidth
              onClose={splitDialogOnClose}
            >
              <DialogContent>
                <OrderSplitForm
                  onClose={splitDialogOnClose}
                  createAfterCallbackFnc={onSplitDialogSubmit}
                  entity={orderSplitEntity}
                />
              </DialogContent>
            </Dialog>
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SelectAllSnackbars
            totalPageCount={requestData?.totalPageCount || 1}
            totalCount={requestData?.totalCount || 0}
            perPage={pageSize}
            allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
            selectedIds={selectedOrderIds}
            apiRef={apiRef}
            setSelectedIds={setSelectedOrderIds}
          />
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`,
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={columnsWithCustomFields}
                pagination
                checkboxSelection
                disableRowGrouping
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    startOn,
                    handleStartOn,
                    endOn,
                    handleEndOn,
                    removeSelectedOrders,
                    resetSelectedOrders,
                    toggleDialogIsOpen,
                    setIsLoading,
                    isLoading,
                    handleDateRange,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadExcel
                  }
                }}
                rowHeight={rowHeight}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading || isLoading}
                onSelectionModelChange={dataGridOnSelectionModelChange}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={dataGridOnColumnVisibilityModelChange}
                onRowDoubleClick={onRowDoubleClick}
                selectionModel={selectedOrderIds}
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                onColumnWidthChange={(params: GridColumnResizeParams) => {
                  dataGridUtil.setSavedColumnWidth(localStorageItemPrefix, params);
                }}
                keepNonExistentRowsSelected
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2OrdersPresenter;
