/* eslint-disable @typescript-eslint/no-misused-promises */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Theme, ThemeOptions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import { CompanyEntity } from 'src/entities/admin/companyEntity';
import { DriverEntity, DriverRequestEntity } from 'src/entities/driverEntity';
import { TruckEntity } from 'src/entities/truckEntity';
import { queryKey } from 'src/hooks/useDrivers.request';
import { useMutationDriver, useQueryDriver } from 'src/hooks/useQueryDrivers';
import { ColorModel } from 'src/models/ColorModel';

import { schema } from './schema';
import SelectTruckDialog from './SelectTruckDialog';

type Props = {
  dialogIsOpen: boolean,
  toggleDialogIsOpen: () => void;
  setDialogIsOpen: (open: boolean) => void;
  entity?: DriverEntity,
  resetDrivers?: () => void;
  driverId?: number;
}

const FormDialog: FC<Props> = memo(({
  dialogIsOpen,
  toggleDialogIsOpen,
  setDialogIsOpen,
  entity,
  resetDrivers,
  driverId,
}) => {
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();
  const theme: Theme & ThemeOptions = useTheme();

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DriverRequestEntity | DriverEntity>({
    resolver: yupResolver(schema)
  });

  const { data: driverData } = useQueryDriver(driverId);
  const { addDriver, updateDriver } = useMutationDriver();
  const [loading, setLoading] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<number>(licenseContext?.config?.selected_company_id ?? 0);
  const [selectTruckDialogIsOpen, setSelectTruckDialogIsOpen] = useState<boolean>(false);
  const [selectableCompanies, setSelectableCompanies] = useState<CompanyEntity[]>([]);
  const [selectedTruckId, setSelectedTruckId] = useState<number | undefined>(undefined);
  const [selectedTruckName, setSelectedTruckName] = useState<string | undefined>(undefined);
  const [editEntity, setEditEntity] = useState<DriverEntity | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const openSelectTruckDialog = () => {
    setSelectTruckDialogIsOpen(true);
  };

  const closeSelectTruckDialog = () => {
    setSelectTruckDialogIsOpen(false);
  };

  const updateSelectedTruck = (maybeTruck: TruckEntity | null) => {
    setSelectedTruckId(maybeTruck?.id);
    setSelectedTruckName(maybeTruck?.license_plate_value);
  };

  const onChangeColor = (event: SelectChangeEvent<string>) => {
    setValue('color', event.target.value);
  };

  const onSubmitForm: SubmitHandler<DriverEntity | DriverRequestEntity> = async (
    data: DriverEntity | DriverRequestEntity
  ): Promise<void> => {
    data.company_id = companyId;
    setLoading(true);
    try {
      if ('id' in data) {
        await updateDriver.mutateAsync(data);
      } else {
        await addDriver.mutateAsync(data);
      }
      if (resetDrivers) {
        resetDrivers();
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
      error.response.data.map((str) => enqueueSnackbar(str));
    } finally {
      queryClient
        .invalidateQueries([queryKey])
        .finally(() => {
          setLoading(false);
          setDialogIsOpen(false);
        });
    }
  };

  const onChangeCompany = (e: SelectChangeEvent) => {
    setValue('company_id', Number(e.target.value));
    setValue('default_truck_id', null);
    setValue('truck_name', null);
    setCompanyId(Number(e.target.value));
  };

  const haveSelectableCompanies = licenseContext?.config?.selectable_companies?.length > 1;
  const isNew = !editEntity;

  useEffect(() => {
    if (driverData) {
      setEditEntity(driverData);
      return;
    }

    if (entity) {
      setEditEntity(entity);
    }
  }, [entity, driverData]);

  useEffect(() => {
    setSelectableCompanies(licenseContext.config?.selectable_companies ?? []);
  }, [licenseContext.config]);

  useEffect(() => {
    console.log('editEntity', editEntity);
    reset(editEntity || { color: ColorModel.colors[0] });
    setCompanyId(editEntity?.company_id || licenseContext?.config?.selected_company_id);
    setSelectedTruckId(editEntity?.default_truck_id || null);
    setSelectedTruckName(editEntity?.truck_name || null);
  }, [dialogIsOpen, editEntity, getValues, reset, licenseContext?.config]);

  useEffect(() => {
    setValue('default_truck_id', selectedTruckId);
  }, [selectedTruckId]);

  useEffect(() => {
    setValue('truck_name', selectedTruckName);
  }, [selectedTruckName]);

  const ready = () => {
    const isEdit = [editEntity, driverId].some((maybe) => maybe);

    if (isEdit) {
      return !!editEntity;
    }

    return true;
  };

  if (!ready()) return null;

  return (
    <>
      <Dialog open={dialogIsOpen} maxWidth="md" fullWidth>
        <DialogTitle>
          ドライバー
          {isNew ? '追加' : '編集'}
        </DialogTitle>
        <DialogContent>
          <Stack component="form" noValidate spacing={2}>
            {[haveSelectableCompanies, isNew].every((bool) => bool) && (
              <FormControl
                fullWidth
                size="small"
                sx={{
                  marginTop: theme.spacing(1),
                }}
              >
                <Controller
                  control={control}
                  name="company_id"
                  render={({ field }) => (
                    <>
                      <InputLabel>事業所</InputLabel>
                      <Select
                        id="company_id"
                        margin="dense"
                        label="事業所"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={`${companyId}`}
                        onChange={onChangeCompany}
                        disabled={!haveSelectableCompanies}
                      >
                        {selectableCompanies.map((company) => (
                          <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
            )}
            <Stack direction="row" gap={1} alignItems="flex-end">
              <Stack
                width="40px"
                alignContent="center"
              >
                <FormControl
                  variant="standard"
                  size="medium"
                >
                  <Controller
                    control={control}
                    name="color"
                    render={({ field }) => (
                      <>
                        <Select
                          id="color"
                          margin="dense"
                          label="計画の色"
                          type="text"
                          variant="standard"
                          {...field}
                          value={getValues('color')}
                          onChange={onChangeColor}
                        >
                          {ColorModel.colors.map((color) => (
                            <MenuItem
                              value={color}
                              key={color}
                            >
                              <Stack
                                borderLeft={20}
                                borderColor={color}
                                spacing={0.5}
                                marginRight={0.5}
                              >
                                &nbsp;
                              </Stack>
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={'color' in errors}>{errors.color?.message}</FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </Stack>
              <Stack
                flexGrow={1}
              >
                <TextField
                  required
                  margin="dense"
                  id="name"
                  label="氏名"
                  type="text"
                  fullWidth
                  variant="standard"
                  {...register('name')}
                  error={'name' in errors}
                  helperText={errors.name?.message}
                />
              </Stack>
              <Stack>
                <Button
                  onClick={openSelectTruckDialog}
                  disabled={companyId === 0}
                >
                  {
                    selectedTruckName || 'トラックを選択してください'
                  }
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" gap={1}>
              <TextField
                margin="dense"
                id="phone_number"
                label="電話番号"
                type="text"
                fullWidth
                variant="standard"
                {...register('phone_number')}
                error={'phone_number' in errors}
                helperText={errors.phone_number?.message}
              />
              <TextField
                margin="dense"
                id="email_address"
                label="メールアドレス"
                type="email"
                fullWidth
                variant="standard"
                {...register('email_address')}
                error={'email_address' in errors}
                helperText={errors.email_address?.message}
              />
            </Stack>
            <Stack direction="row" gap={1}>
              <TextField
                id="shuttle_limit"
                label="ピストン上限回数"
                fullWidth
                variant="standard"
                {...register('shuttle_limit')}
                error={'shuttle_limit' in errors}
                helperText={errors.shuttle_limit?.message}
              />
              <TextField
                id="orders_limit"
                label="案件数上限"
                fullWidth
                variant="standard"
                {...register('orders_limit')}
                error={'orders_limit' in errors}
                helperText={errors.orders_limit?.message}
              />
              <TextField
                id="driver_cost_yen_per_hours"
                label="時給"
                fullWidth
                variant="standard"
                {...register('driver_cost_yen_per_hours')}
                error={'driver_cost_yen_per_hours' in errors}
                helperText={errors.driver_cost_yen_per_hours?.message}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialogIsOpen} disabled={loading}>キャンセル</Button>
          <Button onClick={handleSubmit(onSubmitForm)} variant="contained" disabled={loading}>
            保存する
          </Button>
        </DialogActions>
      </Dialog>
      {
        selectTruckDialogIsOpen && (
          <SelectTruckDialog
            open={selectTruckDialogIsOpen}
            onClose={closeSelectTruckDialog}
            onSelect={updateSelectedTruck}
            currentTruckId={selectedTruckId}
            selectedCompanyId={companyId}
          />
        )
      }
    </>
  );
});

export default FormDialog;
