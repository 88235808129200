import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import YoutubeSearchedForRoundedIcon from '@mui/icons-material/YoutubeSearchedForRounded';
import { LoadingButton } from '@mui/lab';
import {
  Badge, Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel,
  IconButton, Input, InputLabel, MenuItem, Paper, Select,
  Stack, TextField, Tooltip, Typography, useTheme
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import Scrollbar from 'src/components/Scrollbar';
import StyledMenu from 'src/components/StyledMenu';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import stringDecorator from 'src/decorators/string.decorator';
import { CompanySearchCondition } from 'src/entities/CompanySearchCondition.entity';
import { OrderEntity, TruckDriverIdAndName } from 'src/entities/orderEntity';
import { OrderPropertyEntity } from 'src/entities/OrderProperty.entity';
import { OrderSearchConditionEntity } from 'src/entities/OrderSearchCondition.entity';
import { PlanningOrderStatisticsEntity } from 'src/entities/PlanningOrderStatistics.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { SearchConditionRequestEntity } from 'src/entities/SearchCondition.request.entity';
import { useNotAllocatedOrderSearchCondition } from 'src/hooks/NotAllocatedOrderSearchCondition.mutation';
import { OrderFactory } from 'src/models/OrderFactory';
import arrayUtil from 'src/utils/array.util';
import { LogicalOperatorVo } from 'src/vo/LogicalOperator.vo';
import { SearchInputTypeVo } from 'src/vo/SearchInputType.vo';
import { SearchOperatorVo } from 'src/vo/SearchOperator.vo';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import { displayOrderIdsReducer } from '../reducers';

import { GroupedOrdersPresenter } from './GroupedOrdersPresenter';
import OrderFormPresenter from './OrderFormPresenter';
import OrderPresenter from './OrderPresenter';
import OrderSplitFormPresenter from './OrderSplitFormPresenter';
import PlanningOrderStatisticsPresenter from './PlanningOrderStatistics.presenter';

export const SearchOrderProperties: readonly OrderPropertyEntity[] = [
    { key: 'code', value: '注文番号', type: 'string' },
    { key: 'memo', value: '備考', type: 'string' },
    { key: 'shipperName', value: '荷主名', type: 'string' },
    { key: 'shipperPhoneNumber', value: '電話番号', type: 'string' },
    { key: 'shipperEmailAddress', value: 'メールアドレス', type: 'string' },
    { key: 'itemCount', value: '数量', type: 'number' },
    { key: 'itemTotalWeightKg', value: '重量', type: 'number' },
    { key: 'itemTotalVolumeM3', value: '立米', type: 'number' },
    { key: 'itemPackingStyle', value: '荷姿', type: 'string' },
    { key: 'itemKlass', value: '輸送区分', type: 'string' },
    { key: 'itemName', value: '品名', type: 'string' },
    { key: 'itemHandlingOfCargoStyle', value: '荷扱', type: 'string' },
    { key: 'itemCanBeMixed', value: '積み合わせ', type: 'boolean' },
    { key: 'loadingName', value: '積地名称', type: 'string' },
    { key: 'loadingAddress', value: '積地住所', type: 'string' },
    { key: 'loadingStartAt', value: '積地指定開始時間', type: 'time' },
    { key: 'loadingEndAt', value: '積地指定終了時間', type: 'time' },
    { key: 'unloadingName', value: '降地名称', type: 'string' },
    { key: 'unloadingAddress', value: '降地住所', type: 'string' },
    { key: 'unloadingStartAt', value: '降地指定開始時間', type: 'time' },
    { key: 'unloadingEndAt', value: '降地指定終了時間', type: 'time' },
    { key: 'designatedTruckKlasses', value: '指定車両タイプ', type: 'string' },
    { key: 'designatedTruckCarModels', value: '指定車種', type: 'string' },
    { key: 'designatedTruckLoadingPlatformHeights', value: '荷台高さ', type: 'string' },
    { key: 'designatedTruckLoadingPlatformWidths', value: '荷台幅', type: 'string' },
    { key: 'designatedTruckLoadingPlatformLengths', value: '荷台長さ', type: 'string' },
    { key: 'designatedTruckFloorSpecifications', value: '床仕様', type: 'string' },
    { key: 'designatedTruckFeatures', value: '装置', type: 'string' },
    { key: 'chargeBasicFeeYen', value: '基本運賃', type: 'number' },
    { key: 'chargeHighwayFeeYen', value: '高速代', type: 'number' },
    { key: 'chargeLoadingFeeYen', value: '積込料', type: 'number' },
    { key: 'chargeAncillaryFeeYen', value: '付帯業務料', type: 'number' },
    { key: 'chargeWaitingTimeFeeYen', value: '待機時間料', type: 'number' },
    { key: 'chargeUnloadingFeeYen', value: '取卸料', type: 'number' },
    { key: 'chargeExpensesFeeYen', value: '諸経費', type: 'number' },
    { key: 'chargeAncillaryContent', value: '付帯業務内容', type: 'string' },
    { key: 'allowedTrucks', value: '指定トラック', type: 'string' },
    { key: 'deniedDrivers', value: 'NGドライバー', type: 'string' },
  ] as const;

type Prop = {
  ids: number[];
  selectedIds: number[];
  addSelectedId: (id: number) => void;
  removeSelectedId: (id: number) => void;
  unit: string;
  isLoading: boolean;
  startOn: string;
  endOn: string;
  mutateDeleteOrder: () => void;
  mutateDeleteSpecificOrder: (orderId: number) => void;
  mutateCloneOrder: (order: OrderEntity) => void;
  planningOrderStatisticsEntity: PlanningOrderStatisticsEntity | undefined;
  resetAllocateHistories: () => void;
  customInputFields: string[];
  notAllocReasons: PlanningsNotAllocReason[];
  truckEntities: PlanningsTruckEntity[];
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  orderEntityMap: Map<number, OrderEntity>;
  unallocatedDrawerWidth: number;
  orderSearchKw: string;
  setOrderSearchKw: (kw: string) => void;
  currentlyOrderSearching: boolean;
  setCurrentlyOrderSearching: (searching: boolean) => void;
  setOrderSearchConditions: (conditions: OrderSearchConditionEntity[]) => void;
  mutateRestoreSplittedOrder: (orderId: number) => void;
}

const OrdersPresenter: FC<Prop> = memo((
  {
    ids,
    unit,
    isLoading,
    startOn,
    endOn,
    selectedIds,
    addSelectedId,
    removeSelectedId,
    mutateDeleteOrder,
    mutateDeleteSpecificOrder,
    mutateCloneOrder,
    planningOrderStatisticsEntity,
    resetAllocateHistories,
    customInputFields,
    notAllocReasons,
    truckEntities,
    driverEntities,
    deliveryEntities,
    orderEntityMap,
    unallocatedDrawerWidth,
    orderSearchKw,
    setOrderSearchKw,
    currentlyOrderSearching,
    setCurrentlyOrderSearching,
    setOrderSearchConditions,
    mutateRestoreSplittedOrder,
  }
) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const hideOrderPropertyJaObjects: {
    groupTitle: string;
    properties: Omit<OrderPropertyEntity, 'type'>[]
  }[] = useMemo(() => (
    [
      { groupTitle: '基本情報',
        properties: [
          { key: 'code', value: '注文番号' },
          { key: 'note', value: '備考' },
        ]
      },
      { groupTitle: '荷主情報',
        properties: [
          { key: 'shipperName', value: '荷主名' },
          { key: 'phoneNumber', value: '電話番号' },
          { key: 'emailAddress', value: 'メールアドレス' },
        ]
      },
      {
        groupTitle: '荷物情報',
        properties: [
          { key: 'itemCount', value: '数量' },
          { key: 'itemTotalWeightKg', value: '重量' },
          { key: 'itemTotalVolumeM3', value: '立米' },
          { key: 'itemPackingStyle', value: '荷姿' },
          { key: 'itemKlass', value: '輸送区分' },
          { key: 'itemName', value: '品名' },
          { key: 'itemHandlingOfCargoStyle', value: '荷扱' },
          { key: 'itemCanBeMixed', value: '積み合わせ' },
        ]
      },
      {
        groupTitle: '積地情報',
        properties: [
          { key: 'loadingName', value: '名称' },
          { key: 'loadingAddress', value: '住所' },
          { key: 'loadingSpecifiedTime', value: '指定時間' },
        ]
      },
      {
        groupTitle: '降地情報',
        properties: [
          { key: 'unloadingName', value: '名称' },
          { key: 'unloadingAddress', value: '住所' },
          { key: 'unloadingSpecifiedTime', value: '指定時間' },
        ]
      },
      {
        groupTitle: '車両情報',
        properties: [
          { key: 'designatedTruckKlass', value: '指定車両タイプ' },
          { key: 'designatedTruckCarModel', value: '指定車種' },
          { key: 'designatedTruckLoadingPlatformHeight', value: '荷台高さ' },
          { key: 'designatedTruckLoadingPlatformWidth', value: '荷台幅' },
          { key: 'designatedTruckLoadingPlatformLength', value: '荷台長さ' },
          { key: 'designatedTruckFloorSpecification', value: '床仕様' },
          { key: 'designatedTruckFeature', value: '装置' },
        ]
      },
      {
        groupTitle: '料金情報',
        properties: [
          { key: 'chargeBasicFeeYen', value: '基本運賃' },
          { key: 'chargeHighwayFeeYen', value: '高速代' },
          { key: 'chargeLoadingFeeYen', value: '積込料' },
          { key: 'chargeAncillaryFeeYen', value: '付帯業務料' },
          { key: 'chargeWaitingTimeFeeYen', value: '待機時間料' },
          { key: 'chargeUnloadingFeeYen', value: '取卸料' },
          { key: 'chargeExpensesFeeYen', value: '諸経費' },
          { key: 'chargeAncillaryContent', value: '付帯業務内容' },
        ]
      },
      {
        groupTitle: '指定トラック・NGドライバー',
        properties: [
          { key: 'allowedTrucks', value: '指定トラック' },
          { key: 'deniedDrivers', value: 'NGドライバー' },
        ]
      },
    ]
  ), []);

  const customInputProperties: OrderPropertyEntity[] = useMemo(() => (
    customInputFields ? customInputFields.map((it) => ({
      key: it,
      value: it,
      type: 'string'
    })) : []
  ), [customInputFields]);

  const orderProperties: OrderPropertyEntity[] = useMemo(() => (
    [
      ...customInputProperties,
      ...SearchOrderProperties
    ]
  ), [customInputProperties]);

  const searchOperator: {
    [key in SearchInputTypeVo]: SearchOperatorVo[];
  } = useMemo(() => (
    {
      string: [
        '含む',
        '含まない',
        '完全一致',
      ],
      number: [
        '以上',
        '以下',
        '等しい',
      ],
      boolean: [
        '可',
        '不可'
      ],
      time: [
        'よりも未来',
        'よりも過去',
        '同日時',
      ],
    }
  ), []);

  const logicalOperators: LogicalOperatorVo[] = useMemo(() => (
    [
      'or',
      'and',
    ]
  ), []);

  const searchConditionInitialValue: OrderSearchConditionEntity = useMemo(() => (
    {
      logicalOperator: 'or',
      key: undefined,
      operator: undefined,
      value: undefined,
    }
  ), []);

  const { bulkCreation, destroy } = useNotAllocatedOrderSearchCondition();

  const gaClickEvent = useCallback((buttonName: string, label?: string) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.ORDER_ADVANCED_SEARCH, button_name: buttonName, label });
  }, []);

  const deleteCompanySearchConditionOnClick = (conditionId: number) => {
    gaClickEvent('保存済み検索条件 削除');
    const confirm = window.confirm('削除しますか？');
    if (!confirm) return;

    destroy.mutate(conditionId, {
      onSuccess: () => {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useSessionRequest']);

        enqueueSnackbar('検索条件を削除しました', { variant: 'success' });
        setSelectedSavedSearchConditionName('');
      },
      onError: () => {
        enqueueSnackbar('検索条件の削除に失敗しました', { variant: 'error' });
        queryClient.clear();
      },
    });
  };
  const uploadSearchConditionButtonOnClick = (entity: SearchConditionRequestEntity) => {
    gaClickEvent('保存条件共有');
    const selectedCompanyId = licenseContext?.config?.selected_company_id ?? 0;
    const selectableCompanies = licenseContext?.config?.selectable_companies ?? [];

    if (!selectedCompanyId && !selectableCompanies.length) return;

    const selectedAllCompanies = selectedCompanyId === 0;

    if (selectedAllCompanies) {
      const conf = window.confirm('全ての会社に適用しますか？');

      if (!conf) return;
    }

    const companyIds = selectedAllCompanies ? selectableCompanies.map((it) => it.id) : [selectedCompanyId];

    companyIds.map((companyId: number) => {
      const requestEntity: {
        companyId: number;
        conditions: SearchConditionRequestEntity[];
      } = {
        companyId,
        conditions: [entity]
      };

      const companyName = selectableCompanies.find((it) => it.id === companyId)?.name;

      bulkCreation.mutate(requestEntity, {
        onSuccess: () => {
          queryClient.invalidateQueries(['useSessionRequest']).finally(() => {
            enqueueSnackbar(`${companyName}に検索条件を共有しました`, { variant: 'success' });

            setSavedSearchConditions(
              (prev) => prev.filter((it) => it.name !== entity.name)
            );
          });
        },
        onError: () => {
          enqueueSnackbar('検索条件の共有に失敗しました', { variant: 'error' });

          queryClient.clear();
        }
      });

      return companyId;
    });
  };

  const DEFAULT_DISPLAY_ORDER_COUNT = 100;
  const [displayOrderCount, setDisplayOrderCount] = useState(DEFAULT_DISPLAY_ORDER_COUNT);
  const loadingElemntRef = useRef<HTMLDivElement>(null);
  const [loadingIsVisible, setLoadingIsVisible] = useState(false);

  const resetDisplayOrderCount = useCallback(() => {
    setDisplayOrderCount(DEFAULT_DISPLAY_ORDER_COUNT);
  }, []);

  const [searchDialogIsOpen, setSearchDialogIsOpen] = useState<boolean>(false);
  const openSearchDialog = useCallback(() => {
    ReactGA.event('open_order_advanced_search', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件詳細検索' });
    setSearchDialogIsOpen(true);
  }, []);
  const closeSearchDialog = useCallback(() => {
    setSearchDialogIsOpen(false);
  }, []);

  const searchButtonOnClick = () => {
    ReactGA.event('apply_order_advanced_search', { screen_name: SCREEN_NAMES.ORDER_ADVANCED_SEARCH, button_name: '検索', search_filters: searchConditions });
    closeSearchDialog();
    setCurrentlyOrderSearching(true);
  };
  const resetSearchButtonOnClick = useCallback(() => {
    gaClickEvent('詳細検索リセット');
    setCurrentlyOrderSearching(false);
    resetDisplayOrderCount();
  }, [gaClickEvent, resetDisplayOrderCount, setCurrentlyOrderSearching]);

  useEffect(() => {
    if (currentlyOrderSearching) setOrderSearchKw('');
  }, [currentlyOrderSearching]);

  const [searchConditions, setSearchConditions] = useState<OrderSearchConditionEntity[]>([searchConditionInitialValue]);

  useEffect(() => {
    setOrderSearchConditions(searchConditions);
  }, [searchConditions, setOrderSearchConditions]);

  const [currentValue, setCurrentValue] = useState<{
    idx: number;
    value: string;
  } | undefined>(undefined);

  const updateLogicalOperator = useCallback((index: number, logicalOperator: LogicalOperatorVo) => {
    setSearchConditions(
      (prev) => prev.map((it, i) => {
        if (i !== index) return it;

        return {
          ...it,
          logicalOperator,
        };
      })
    );
  }, []);

  const updateSearchKey = useCallback((index: number, key: string | undefined) => {
    setSearchConditions(
      (prev) => prev.map((it, i) => {
        if (i !== index) return it;

        return {
          ...it,
          key,
          operator: undefined,
          value: undefined
        };
      })
    );

    setCurrentValue((prev) => {
      if (prev?.idx === index) {
        return undefined;
      }

      return prev;
    });
  }, []);

  const updateSearchOperator = useCallback((index: number, operator: SearchOperatorVo | undefined) => {
    setSearchConditions(
      (prev) => prev.map((it, i) => {
        if (i !== index) return it;

        if (operator === '可' || operator === '不可') {
          return {
            ...it,
            operator,
            value: operator
          };
        }

        return {
          ...it,
          operator,
        };
      })
    );
  }, []);

  const updateSearchValue = useCallback((index: number, value: string | undefined) => {
    setSearchConditions(
      (prev) => prev.map((it, i) => {
        if (i !== index) return it;

        return {
          ...it,
          value,
        };
      })
    );
  }, []);

  const addSearchConditions = useCallback(() => {
    gaClickEvent('条件追加');
    setSearchConditions(
      (prev) => [
        ...prev,
        searchConditionInitialValue
      ]
    );
  }, [gaClickEvent, searchConditionInitialValue]);

  const deleteSearchConditions = useCallback((index: number) => {
    gaClickEvent('条件削除');
    setSearchConditions(
      (prev) => prev.filter((it, i) => i !== index)
    );
  }, [gaClickEvent]);

  const [saveNewSearchConditionDialogIsOpen, setSaveNewSearchConditionDialogIsOpen] = useState<boolean>(false);
  const openSaveNewSearchConditionDialog = useCallback(() => {
    gaClickEvent('条件保存');
    setSaveNewSearchConditionDialogIsOpen(true);
  }, [gaClickEvent]);
  const closeSaveNewSearchConditionDialog = useCallback(() => {
    setSaveNewSearchConditionDialogIsOpen(false);
  }, []);

  const [companySearchConditions, setCompanySearchConditions] = useState<CompanySearchCondition[]>([]);
  useEffect(() => {
    if (!licenseContext.config) return;
    if (!licenseContext.config.company_search_conditions) return;

    if (licenseContext.config.selected_company_id === 0) {
      setCompanySearchConditions(
        licenseContext.config.company_search_conditions
      );
      return;
    }

    setCompanySearchConditions(
      licenseContext.config.company_search_conditions.filter((it: CompanySearchCondition) => it.companyId === licenseContext.config.selected_company_id)
    );
  }, [licenseContext.config]);

  const [savedSearchConditions, setSavedSearchConditions] = useState<SearchConditionRequestEntity[]>([]);
  const [selectedSavedSearchConditionName, setSelectedSavedSearchConditionName] = useState<string>('');
  const companySearchConditionIdPrefix = '-||-||--id:';
  const updateSelectedSavedSearchConditionName = useCallback((val: string) => {
    gaClickEvent('保存済み検索条件選択', val);
    setSelectedSavedSearchConditionName(val);
  }, [gaClickEvent]);

  const restoreSavedSearchConditionButtonOnClick = useCallback(() => {
    gaClickEvent('読み込む');
    const hasIdPrefix = selectedSavedSearchConditionName.indexOf(companySearchConditionIdPrefix) !== -1;

    const searchCompanyCondition = () => {
      const id = [...selectedSavedSearchConditionName.split(companySearchConditionIdPrefix)].slice(-1)[0];
      return companySearchConditions.find((it) => `${it.id}` === id)?.searchConditions;
    };

    const searchLocalCondition = () => savedSearchConditions.find((it) => it.name === selectedSavedSearchConditionName)?.searchConditions;

    const restoreSearchConditions = hasIdPrefix ? searchCompanyCondition() : searchLocalCondition();

    if (!restoreSearchConditions) return;

    const modRestoreSearchConditions = restoreSearchConditions.map((it) => {
      if (it.operator === 'よりも未来' || it.operator === 'よりも過去' || it.operator === '同日時') {
        // 日時から時刻だけに変更したので変換する
        if (it.value.includes('T')) {
          const d = new Date(it.value);
          const hour = d.getHours().toString().padStart(2, '0');
          const minute = d.getMinutes().toString().padStart(2, '0');
          return { ...it, value: `${hour}:${minute}` };
        }
      }
      return it;
    });

    setSearchConditions(modRestoreSearchConditions);
    modRestoreSearchConditions.forEach((it, idx) => {
      setCurrentValue({ idx, value: it.value });
    });
    resetDisplayOrderCount();
  }, [companySearchConditions, gaClickEvent, resetDisplayOrderCount, savedSearchConditions, selectedSavedSearchConditionName]);

  const removeSavedSearchConditionButtonOnClick = useCallback((val: string) => {
    gaClickEvent('保存済み検索条件 削除');
    const confirm = window.confirm('削除しますか？');

    if (!confirm) return;

    setSavedSearchConditions(
      (prev) => prev.filter((it) => it.name !== val)
    );
    setSelectedSavedSearchConditionName('');
  }, [gaClickEvent]);
  const newSearchConditionInitialValue = useCallback(() => (
    `新しい検索条件 ${datetimeDecorator.toMmDd(new Date())} ${datetimeDecorator.toHourMinuteSeconds(new Date())}`
  ), []);
  const [newSearchConditionName, setNewSearchConditionName] = useState<string>(newSearchConditionInitialValue());
  const updateNewSearchConditionName = useCallback((val: string) => {
    setNewSearchConditionName(val);
  }, []);

  const saveNewSearchCondition = useCallback(() => {
    if (savedSearchConditions.map((it) => it.name).includes(newSearchConditionName)) {
      enqueueSnackbar(`${newSearchConditionName}は、既に利用されています`);
      return;
    }

    setSavedSearchConditions(
      (prev) => {
        if (prev.map((it) => it.name).includes(newSearchConditionName)) {
          enqueueSnackbar(`${newSearchConditionName}は、既に利用されています`);

          return [
            ...prev
          ];
        }

        return [
          ...prev,
          {
            name: newSearchConditionName,
            searchConditions
          }
        ];
      }
    );
    closeSaveNewSearchConditionDialog();
    setNewSearchConditionName(
      newSearchConditionInitialValue()
    );
  }, [closeSaveNewSearchConditionDialog, enqueueSnackbar, newSearchConditionInitialValue, newSearchConditionName, savedSearchConditions, searchConditions]);

  useEffect(() => {
    const conditionsFromStorage = localStorage.getItem('saved-search-conditions')
      ? JSON.parse(localStorage.getItem('saved-search-conditions')) as SearchConditionRequestEntity[] : [];

    setSavedSearchConditions(conditionsFromStorage);
  }, []);
  useEffect(() => {
    if (!savedSearchConditions) return;

    localStorage.setItem('saved-search-conditions', JSON.stringify(savedSearchConditions));
  }, [savedSearchConditions]);

  const initialDisplayOrderProperties = useMemo(() => (
    [
      'shipperName',
      'itemCount',
      'itemTotalWeightKg',
      'itemTotalVolumeM3',
      'loadingName',
      'loadingSpecifiedTime',
      'unloadingName',
      'unloadingSpecifiedTime',
    ]
  ), []);

  const [hideOrderProperties, setHideOrderProperties] = useState<string[]>([]);
  const addHideOrderProperties = useCallback((hideOrderProperty: string) => {
    setHideOrderProperties(
      (prev) => [
        ...prev.filter((it) => it !== hideOrderProperty),
        hideOrderProperty
      ]
    );
  }, []);
  const removeHideOrderProperties = useCallback((hideOrderProperty: string) => {
    setHideOrderProperties(
      (prev) => prev.filter((it) => it !== hideOrderProperty)
    );
  }, []);

  const hideOrderPropertiesDialogSelectBoxOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (event.target.checked) {
      removeHideOrderProperties(key);
    } else {
      addHideOrderProperties(key);
    }
  }, [addHideOrderProperties, removeHideOrderProperties]);

  const [hideOrderPropertiesDialogIsOpen, setHideOrderPropertiesDialogIsOpen] = useState<boolean>(false);
  const openHideOrderPropertiesDialog = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件表示項目選択' });
    setDisplaySettingsAnchorEl(null);
    setHideOrderPropertiesDialogIsOpen(true);
  }, []);
  const closeHideOrderPropertiesDialog = useCallback(() => {
    const displayFields = [
        ...customInputFields.map((it) => ({ key: it, value: it })),
        ...hideOrderPropertyJaObjects.flatMap((it) => it.properties)
      ].filter((it) => !hideOrderProperties.includes(it.key)).map((it) => it.value);
    ReactGA.event('apply', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件表示項目選択', label: displayFields });
    setHideOrderPropertiesDialogIsOpen(false);
  }, [customInputFields, hideOrderProperties, hideOrderPropertyJaObjects]);

  const [toEditOrderId, setToEditOrderId] = useState<number>(null);
  const [displayOnlySelected, setDisplayOnlySelected] = useState<boolean>(false);
  const [displayIds, dispatchDisplayIds] = useReducer(displayOrderIdsReducer, []);
  const addDisplayIds = useCallback(
    (id: number) => {
      dispatchDisplayIds({
        type: 'add',
        payload: id
      });
    },
    [],
  );
  const resetDisplayIds = useCallback(() => {
    dispatchDisplayIds({
      type: 'reset'
    });
  }, []);

  useEffect(() => {
    dispatchDisplayIds({ type: 'reset' });
  }, [startOn, endOn]);

  const [selectedIdsLength, setSelectedIdsLength] = useState<number>(0);
  useEffect(() => {
    setSelectedIdsLength(selectedIds.length);
  }, [selectedIds]);
  const [selectedState, setSelectedState] = useState<SelectedStatusVo>('none');
  useEffect(() => {
    if (!ids) return;

    if (!selectedIdsLength) {
      setSelectedState('none');
      return;
    }

    if (displayIds.every((displayId) => selectedIds.includes(displayId))) {
      setSelectedState('every');
      return;
    }

    setSelectedState('some');
  }, [selectedIds, displayIds, selectedIdsLength, ids]);

  const [confirmedKw, setConfirmedKw] = useState('');
  const [isImeInputting, setIsImeInputting] = useState(false);

  useEffect(() => {
    if (orderSearchKw) setCurrentlyOrderSearching(false);
  }, [orderSearchKw]);

  useEffect(() => {
    if (!isImeInputting) {
      setConfirmedKw(orderSearchKw);
    }
  }, [isImeInputting, orderSearchKw]);

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = useCallback(() => {
    setToEditOrderId(selectedIds.slice(-1)[0]);
    setDialogIsOpen(!dialogIsOpen);
  }, [dialogIsOpen, selectedIds]);

  const dialogOnClose = useCallback(() => {
    setDialogIsOpen(false);
  }, []);

  const dialogOnCloseWithResetAllocateHistories = useCallback(() => {
    resetAllocateHistories();
    setDialogIsOpen(false);
  }, [resetAllocateHistories]);

  const [splitDialogIsOpen, setSplitDialogIsOpen] = useState<boolean>(false);
  const toggleSplitDialogIsOpen = useCallback(() => {
    setToEditOrderId(selectedIds.slice(-1)[0]);
    setSplitDialogIsOpen(!splitDialogIsOpen);
  }, [selectedIds, splitDialogIsOpen]);
  const splitDialogOnClose = useCallback(() => {
    setSplitDialogIsOpen(false);
  }, []);

  const splitDialogOnCloseWithResetAllocateHistories = useCallback(() => {
    resetAllocateHistories();
    setSplitDialogIsOpen(false);
  }, [resetAllocateHistories]);

  const onClickSplit = useCallback(() => {
    toggleSplitDialogIsOpen();
  }, [toggleSplitDialogIsOpen]);

  const onSplitDialogSubmit = useCallback(() => {
    dialogOnClose();
  }, [dialogOnClose]);

  const textFieldOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newKw = event.target.value.split('　').join(' ');
    ReactGA.event('order_search', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件テキスト検索', search_query: newKw });
    setOrderSearchKw(newKw);
    resetDisplayOrderCount();
  }, [resetDisplayOrderCount, setOrderSearchKw]);

  const checkboxOnChange = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `案件すべて選択 ${selectedState === 'every' ? 'OFF' : 'ON'}` });
    if (selectedState === 'every') {
      displayIds.map((id) => {
        removeSelectedId(id);
        return id;
      });
      return;
    }

    displayIds.map((id) => {
      addSelectedId(id);
      return id;
    });
  }, [addSelectedId, displayIds, removeSelectedId, selectedState]);

  const deleteSelectedOrders = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '選択した案件を削除' });
    if (window.confirm('選択した案件を削除します。よろしいですか？')) {
      resetAllocateHistories();
      mutateDeleteOrder();
    }
  }, [mutateDeleteOrder, resetAllocateHistories]);

  const displayOnlySelectedButtonOnClick = useCallback(() => {
    ReactGA.event('select', { screen_name: SCREEN_NAMES.PLANNING, button_name: '案件表示選択', label: displayOnlySelected ? 'すべて表示' : '選択中のみ表示' });
    setDisplayOnlySelected(
      !displayOnlySelected
    );
    resetDisplayOrderCount();
    setDisplayOnlySelectedAnchorEl(null);
  }, [displayOnlySelected, resetDisplayOrderCount]);

  useEffect(() => {
    displayIds.filter((it) => !ids.includes(it)).map((it) => {
      dispatchDisplayIds(
        {
          type: 'remove',
          payload: it
        }
      );

      return it;
    });
  }, [displayIds, ids]);

  useEffect(() => {
    if (!selectedIds.length) {
      setDisplayOnlySelected(false);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (selectedState === 'every') {
      setDisplayOnlySelected(false);
    }
  }, [selectedState]);

  useEffect(() => {
    if (!hideOrderProperties || !hideOrderProperties.length) return;
    if (!licenseContext) return;
    if (!licenseContext.config) return;

    localStorage.setItem(`hide-order-properties-${licenseContext.config.id}`, JSON.stringify(hideOrderProperties));
  }, [hideOrderProperties, licenseContext, licenseContext.config?.id]);

  useEffect(() => {
    if (!licenseContext) return;
    if (!licenseContext.config) return;

    const hideOrderPropertiesFromStorage: string[] = JSON.parse(localStorage.getItem(`hide-order-properties-${licenseContext.config.id}`)) as string[];

    if (hideOrderPropertiesFromStorage && hideOrderPropertiesFromStorage.length) {
      setHideOrderProperties(hideOrderPropertiesFromStorage);
    } else {
      setHideOrderProperties(
        hideOrderPropertyJaObjects
          .flatMap(({ properties }) => properties)
          .map(({ key }) => key).filter((it) => !initialDisplayOrderProperties.includes(it))
      );
    }
  }, [licenseContext, licenseContext.config?.id, hideOrderPropertyJaObjects, initialDisplayOrderProperties]);

  const searchByKeyword = useCallback((data: OrderEntity) => {
    const model = new OrderFactory(data);
    const searchKws = confirmedKw.split(' ');
    return searchKws.every((k) => model.searchKw().indexOf(k) !== -1);
  }, [confirmedKw]);

  const searchByConditions = useCallback((data: OrderEntity) => {
    const validateIfTheSearchConditionsAreMet = (orderEntity: OrderEntity, condition: OrderSearchConditionEntity) => {
      const propertyName = stringDecorator.toUnderscoreCase(condition.key);
      const isCustomInputFields = orderEntity.custom_input_fields.map((it) => it.key).includes(condition.key);

      const value: any | TruckDriverIdAndName[] = isCustomInputFields
        ? orderEntity.custom_input_fields
          .find((it) => it.key === condition.key)
          ?.value
        : orderEntity[propertyName as keyof OrderEntity];

      if (!(value || value === 0)) return false;

      const truckDriverIdAndName: TruckDriverIdAndName[] | undefined = ['allowed_trucks', 'denied_drivers'].includes(propertyName) && value as TruckDriverIdAndName[];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const modifiedValue: string = truckDriverIdAndName ? truckDriverIdAndName.map((it) => it.name).join(' ') : value;

      if (condition.operator === '含む') return modifiedValue.toString().includes(condition.value);
      if (condition.operator === '含まない') return !modifiedValue.toString().includes(condition.value);
      if (condition.operator === '完全一致') return modifiedValue.toString() === condition.value;
      if (condition.operator === '以上') return Number(condition.value) <= Number(modifiedValue);
      if (condition.operator === '以下') return Number(condition.value) >= Number(modifiedValue);
      if (condition.operator === '等しい') return Number(condition.value) === Number(modifiedValue);
      if (condition.operator === '可') return !!condition.value;
      if (condition.operator === '不可') return !condition.value;

      if (condition.operator === 'よりも未来' || condition.operator === 'よりも過去' || condition.operator === '同日時') {
        const conditionValue = new Date(`${startOn} ${condition.value}`).getTime();
        const actualValue = new Date(modifiedValue.toString()).getTime();
        if (condition.operator === 'よりも未来') return conditionValue <= actualValue;
        if (condition.operator === 'よりも過去') return conditionValue >= actualValue;
        if (condition.operator === '同日時') return conditionValue === actualValue;
      }

      return false;
    };

    const conditions = searchConditions.filter((it) => Object.values(it).every((maybe) => maybe));
    const orSearchConditions = conditions
      .filter((condition) => condition.logicalOperator === 'or');
    const andSearchConditions = conditions
      .filter((condition) => condition.logicalOperator === 'and');

    const matchOrSearchConditions = orSearchConditions
      .some((condition) => validateIfTheSearchConditionsAreMet(data, condition));
    const matchAndSearchConditions = andSearchConditions
      .every((condition) => validateIfTheSearchConditionsAreMet(data, condition));

    return matchOrSearchConditions && matchAndSearchConditions;
  }, [searchConditions, startOn]);

  const canDisplayBySelection = useCallback((data: OrderEntity) => {
    if (!data) return false;

    if (displayOnlySelected && !selectedIds.includes(data.id)) {
      return false;
    }
    return true;
  }, [displayOnlySelected, selectedIds]);

  const canDisplayBySearch = useCallback((data: OrderEntity) => {
    if (!data) {
      // 表示したいデータがまだ取得できていないことがある。あとで取得できれば表示できる。
      return false;
    }

    if (confirmedKw) {
      return searchByKeyword(data);
    }
    if (currentlyOrderSearching && searchConditions) {
      return searchByConditions(data);
    }
    return true;
  }, [currentlyOrderSearching, confirmedKw, searchByConditions, searchByKeyword, searchConditions]);

  const [useGrouping, setUseGrouping] = useState(false);
  const [groupingMenuAnchorEl, setGroupingMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleGroupingDialogOpen = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: useGrouping ? 'まとめを解除する' : '案件をまとめる' });
    setDisplaySettingsAnchorEl(null);
    if (!useGrouping) {
      setGroupingMenuAnchorEl(null);
      setGroupingConditionDialogIsOpen(true);
    } else {
      setUseGrouping((prev) => !prev);
    }
  }, [useGrouping]);

  const [groupingConditionDialogIsOpen, setGroupingConditionDialogIsOpen] = useState(false);
  const [groupingConditions, setGroupingConditions] = useState<string[]>([]);
  const [tmpGroupingConditions, setTmpGroupingConditions] = useState<string[]>([]);
  const defaultGroupingConditions = useMemo(() => ['loadingAddress', 'unloadingAddress'], []);

  useEffect(() => {
    const conditionsFromStorage = JSON.parse(localStorage.getItem('order-list-grouping-conditions')) as string[];
    if (conditionsFromStorage && conditionsFromStorage.length > 0) {
      setGroupingConditions(conditionsFromStorage);
      setTmpGroupingConditions(conditionsFromStorage);
    } else {
      setGroupingConditions(defaultGroupingConditions);
    }
  }, [defaultGroupingConditions]);

  useEffect(() => {
    localStorage.setItem('order-list-grouping-conditions', JSON.stringify(groupingConditions));
  }, [groupingConditions]);

  const groupingConditionDialogOnClose = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.ORDER_GROUPING, button_name: 'キャンセル' });
    setGroupingConditionDialogIsOpen(false);
    setTmpGroupingConditions(groupingConditions);
  }, [groupingConditions]);

  const doGrouping = useCallback(() => {
    ReactGA.event('apply', { screen_name: SCREEN_NAMES.ORDER_GROUPING, button_name: 'OK', label: tmpGroupingConditions });
    setGroupingConditionDialogIsOpen(false);
    setGroupingConditions(tmpGroupingConditions);
    if (!useGrouping) {
      // 案件をまとめる場合は、まとめる対象をすべて選択しているかわからないので選択を解除する。
      selectedIds.forEach((it) => removeSelectedId(it));
    }
    setUseGrouping((prev) => !prev);
  }, [removeSelectedId, selectedIds, tmpGroupingConditions, useGrouping]);

  const groupingConditionObjects: {
    groupTitle: string;
    properties: Omit<OrderPropertyEntity, 'type'>[]
  }[] = useMemo(() => (
    [
      { groupTitle: '荷主情報',
        properties: [
          { key: 'shipperName', value: '荷主名' },
        ]
      },
      {
        groupTitle: '積地情報',
        properties: [
          { key: 'loadingName', value: '名称' },
          { key: 'loadingAddress', value: '住所' },
          { key: 'loadingSpecifiedTime', value: '指定時間' },
        ]
      },
      {
        groupTitle: '降地情報',
        properties: [
          { key: 'unloadingName', value: '名称' },
          { key: 'unloadingAddress', value: '住所' },
          { key: 'unloadingSpecifiedTime', value: '指定時間' },
        ]
      },
    ]
  ), []);

  const groupingConditionDialogMemo = useMemo(() => (
    <Dialog
      open={groupingConditionDialogIsOpen}
      maxWidth="sm"
      fullWidth
      onClose={groupingConditionDialogOnClose}
    >
      <DialogTitle>案件グループ化設定</DialogTitle>
      <DialogContent>
        <DialogContentText mb={3}>
          グループ化の条件に使用する項目を選択してください。
          <br />
          選択された項目の値が同じものをグループ化します。
          <br />
          選択されていない項目は値が異なっていてもグループ化されます。
          <br />
        </DialogContentText>
        {groupingConditionObjects.map(({ groupTitle, properties }) => (
          <Stack divider={<Divider />} key={['grouping', groupTitle].join('-')}>
            <Typography>{groupTitle}</Typography>
            <Stack direction="row" gap={1}>
              {
                properties.map(({ key, value }) => (
                  <FormControlLabel
                    key={['groupingConditionDialog', groupTitle, key].join('-')}
                    label={value}
                    control={(
                      <Checkbox
                        checked={tmpGroupingConditions.includes(key)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setTmpGroupingConditions((prev) => [...prev, key]);
                          } else {
                            setTmpGroupingConditions((prev) => prev.filter((it) => it !== key));
                          }
                        }}
                      />
                    )}
                  />
                ))
              }
            </Stack>
          </Stack>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={groupingConditionDialogOnClose}>
          キャンセル
        </Button>
        <Button onClick={doGrouping}>OK</Button>
      </DialogActions>
    </Dialog>
  ), [groupingConditionDialogIsOpen, groupingConditionDialogOnClose, groupingConditionObjects, doGrouping, tmpGroupingConditions]);

  const groupedOrderIds = useMemo(() => {
    if (!orderEntityMap) return new Map<number, number[]>();

    const orders = Array.from(orderEntityMap.values()).filter((it) => ids.includes(it.id));
    return orders.filter((it) => canDisplayBySearch(it)).sort((a, b) => {
      let ret = 0;
      if (groupingConditions.includes('shipperName')) {
        if (a.shipper_name < b.shipper_name) return -1;
        if (a.shipper_name > b.shipper_name) return 1;
      }
      if (groupingConditions.includes('loadingName')) {
        if (a.loading_name < b.loading_name) return -1;
        if (a.loading_name > b.loading_name) return 1;
      }
      if (groupingConditions.includes('loadingAddress')) {
        ret = a.loading_lat - b.loading_lat;
        if (ret !== 0) return ret;
        ret = a.loading_lng - b.loading_lng;
        if (ret !== 0) return ret;
      }
      if (groupingConditions.includes('loadingSpecifiedTime')) {
        if (a.loading_start_at < b.loading_start_at) return -1;
        if (a.loading_start_at > b.loading_start_at) return 1;
        if (a.loading_end_at > b.loading_end_at) return -1;
        if (a.loading_end_at < b.loading_end_at) return 1;
      }
      if (groupingConditions.includes('unloadingName')) {
        if (a.unloading_name < b.unloading_name) return -1;
        if (a.unloading_name > b.unloading_name) return 1;
      }
      if (groupingConditions.includes('unloadingAddress')) {
        ret = a.unloading_lat - b.unloading_lat;
        if (ret !== 0) return ret;
        ret = a.unloading_lng - b.unloading_lng;
        if (ret !== 0) return ret;
      }
      if (groupingConditions.includes('unloadingSpecifiedTime')) {
        if (a.unloading_start_at < b.unloading_start_at) return -1;
        if (a.unloading_start_at > b.unloading_start_at) return 1;
        if (a.unloading_end_at > b.unloading_end_at) return -1;
        if (a.unloading_end_at < b.unloading_end_at) return 1;
      }
      return ret;
    }).reduce((accum, value, index, arry) => {
      if (index === 0) {
        return accum.set(value.id, []);
      }
      const prev = arry[index - 1];
      let sameLocation = true;
      if (groupingConditions.includes('shipperName')) {
        sameLocation = prev.shipper_name === value.shipper_name;
      }
      if (sameLocation && groupingConditions.includes('loadingName')) {
        sameLocation = prev.loading_name === value.loading_name;
      }
      if (sameLocation && groupingConditions.includes('loadingAddress')) {
        sameLocation = prev.loading_lat === value.loading_lat && prev.loading_lng === value.loading_lng;
      }
      if (sameLocation && groupingConditions.includes('loadingSpecifiedTime')) {
        sameLocation = prev.loading_start_at === value.loading_start_at && prev.loading_end_at === value.loading_end_at;
      }
      if (sameLocation && groupingConditions.includes('unloadingName')) {
        sameLocation = prev.unloading_name === value.unloading_name;
      }
      if (sameLocation && groupingConditions.includes('unloadingAddress')) {
        sameLocation = prev.unloading_lat === value.unloading_lat && prev.unloading_lng === value.unloading_lng;
      }
      if (sameLocation && groupingConditions.includes('unloadingSpecifiedTime')) {
        sameLocation = prev.unloading_start_at === value.unloading_start_at && prev.unloading_end_at === value.unloading_end_at;
      }
      if (sameLocation) {
        const lastKey = Array.from(accum.keys()).slice(-1)[0];
        const grouped = accum.get(lastKey);
        grouped.push(value.id);
        accum.set(lastKey, grouped);
      } else {
        accum.set(value.id, []);
      }
      return accum;
    }, new Map<number, number[]>());
  }, [canDisplayBySearch, groupingConditions, ids, orderEntityMap]);

  const buildOrderDisplay = useCallback((id:number, data: OrderEntity) => (
    <OrderPresenter
      id={id}
      data={data}
      addSelectedId={addSelectedId}
      removeSelectedId={removeSelectedId}
      selected={selectedIds.includes(id)}
      isLoading={isLoading}
      setToEditOrderId={setToEditOrderId}
      setDialogIsOpen={setDialogIsOpen}
      setSplitDialogIsOpen={setSplitDialogIsOpen}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      mutateCloneOrder={mutateCloneOrder}
      customInputFields={customInputFields}
      hideOrderProperties={hideOrderProperties}
      notAllocReasons={notAllocReasons}
      truckEntities={truckEntities}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
    />
  ), [addSelectedId, customInputFields, deliveryEntities, driverEntities, hideOrderProperties, isLoading, mutateCloneOrder, mutateDeleteSpecificOrder, notAllocReasons, removeSelectedId, selectedIds, truckEntities, mutateRestoreSplittedOrder]);

  const buildGroupedOrderDisplay = useCallback((id:number, data: OrderEntity, grouped: number[]) => (
    <GroupedOrdersPresenter
      id={id}
      data={data}
      selectedIds={selectedIds}
      addSelectedId={addSelectedId}
      removeSelectedId={removeSelectedId}
      groupedOrderIds={grouped}
      orderEntityMap={orderEntityMap}
      groupingConditions={groupingConditions}
      buildOrderDiplay={buildOrderDisplay}
      unit={unit}
    />
  ), [addSelectedId, buildOrderDisplay, groupingConditions, orderEntityMap, removeSelectedId, selectedIds, unit]);

  const draggableElementId = 'external-events';
  const draggableElementClassName = 'external-event';

  const ordersMemo = useMemo(() => {
    const buildDisplayIds = (useGrouping ? Array.from(groupedOrderIds.keys()) : ids);
    resetDisplayIds();

    const items = [];
    buildDisplayIds.forEach((id) => {
      const data = orderEntityMap.get(id);
      const displayBySelection = canDisplayBySelection(data);
      const displayBySearch = canDisplayBySearch(data);
      if (displayBySearch) {
        addDisplayIds(id);
        if (useGrouping) {
          groupedOrderIds.get(id).forEach((it) => addDisplayIds(it));
        }
      }
      const display = displayBySelection && displayBySearch;
      const grouped = useGrouping ? groupedOrderIds.get(id) : [];

      if (display && items.length <= displayOrderCount) {
        items.push(
          <Stack
            key={['OrderPresenter', id].join('-')}
            sx={{ display: display ? '' : 'none' }}
            className={draggableElementClassName}
            data-entity={JSON.stringify(data)}
            data-groupedids={grouped}
          >
            {useGrouping
            ? buildGroupedOrderDisplay(id, data, grouped)
            : buildOrderDisplay(id, data) }
          </Stack>
        );
      }
      if (items.length === displayOrderCount) {
        items.push(<div key="nowLoading" ref={loadingElemntRef}>読込中 ...</div>);
      }
    });
    if (items.length === 0) {
      if (isLoading) {
        items.push(<div key="initialLoading">読込中 ...</div>);
      } else {
        items.push(<div key="searchNotFound">案件が見つかりません</div>);
      }
    }

    return (
      <Stack
        pt={16}
        id={draggableElementId}
        sx={{ mb: 2 }}
      >
        {items}
      </Stack>
    );
  }, [isLoading, displayOrderCount, useGrouping, groupedOrderIds, ids, orderEntityMap, canDisplayBySelection, canDisplayBySearch, buildGroupedOrderDisplay, buildOrderDisplay, addDisplayIds, resetDisplayIds]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setLoadingIsVisible((prev) => (entry.isIntersecting !== prev ? !prev : prev));
    });

    if (loadingElemntRef.current) {
      observer.observe(loadingElemntRef.current);
    }

    return () => observer.disconnect();
  }, [ordersMemo, loadingElemntRef]);

  useEffect(() => {
    if (loadingIsVisible) {
      setDisplayOrderCount((prev) => prev + DEFAULT_DISPLAY_ORDER_COUNT);
    }
  }, [loadingIsVisible]);

  const planningOrderStatisticsPresenterMemo = useMemo(() => (
    <PlanningOrderStatisticsPresenter
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      totalCount={ids.length}
    />
  ), [planningOrderStatisticsEntity, ids]);

  const checkboxWithBadgeMemo = useMemo(() => (
    <Badge
      color="primary"
      badgeContent={selectedIdsLength}
      max={10000}
    >
      <Tooltip
        title={
          selectedState === 'every' ? 'すべて選択解除する' : 'すべて選択する'
        }
        arrow
      >
        <Checkbox
          checked={selectedState === 'every'}
          indeterminate={selectedState === 'some'}
          disabled={isLoading || !ids.length}
          onChange={() => {
            checkboxOnChange();
          }}
        />
      </Tooltip>
    </Badge>
  ), [checkboxOnChange, ids.length, isLoading, selectedIdsLength, selectedState]);

  const deleteOrderIconMemo = useMemo(() => (
    <Tooltip
      title="選択したものを削除する"
      arrow
    >
      <span>
        <IconButton
          disabled={isLoading || !selectedIds.length}
          onClick={deleteSelectedOrders}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </span>
    </Tooltip>
  ), [deleteSelectedOrders, isLoading, selectedIds.length]);

  const keywordSearchTextMemo = useMemo(() => (
    !currentlyOrderSearching && (
      <FormControl
        variant="outlined"
        sx={{
          ml: 1
        }}
        fullWidth
      >
        <Tooltip
          title="フリーワードで検索する"
          arrow
        >
          <Input
            value={orderSearchKw}
            onChange={textFieldOnChange}
            onCompositionStart={() => setIsImeInputting(true)}
            onCompositionEnd={() => setIsImeInputting(false)}
            placeholder="検索"
            startAdornment={<SearchRoundedIcon />}
          />
        </Tooltip>
      </FormControl>
    )
  ), [currentlyOrderSearching, orderSearchKw, textFieldOnChange]);

  const resetSearchButtonMemo = useMemo(() => (
    currentlyOrderSearching && (
      <Button
        disabled={isLoading}
        onClick={resetSearchButtonOnClick}
        startIcon={<RotateLeftRoundedIcon />}
        color="error"
      >
        検索をリセット
      </Button>
    )
  ), [currentlyOrderSearching, isLoading, resetSearchButtonOnClick]);

  const detailSearchIconMemo = useMemo(() => (
    !currentlyOrderSearching && (
      <Tooltip
        title="詳細な検索条件を設定する"
        arrow
      >
        <span>
          <IconButton
            disabled={isLoading}
            onClick={openSearchDialog}
          >
            <ContentPasteSearchRoundedIcon />
          </IconButton>
        </span>
      </Tooltip>
    )
  ), [currentlyOrderSearching, isLoading, openSearchDialog]);

  const [displaySettingsAnchorEl, setDisplaySettingsAnchorEl] = useState<null | HTMLElement>(null);
  const displaySettingsMenuOpen = Boolean(displaySettingsAnchorEl);
  const onClickOpenDisplaySettingsMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDisplaySettingsAnchorEl(event.currentTarget);
  }, []);
  const onClickCloseDisplaySettingsMenu = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setDisplaySettingsAnchorEl(null);
  }, []);

  const displaySettingsMemo = useMemo(() => (
    <StyledMenu
      anchorEl={displaySettingsAnchorEl}
      open={displaySettingsMenuOpen}
      onClose={onClickCloseDisplaySettingsMenu}
    >
      <MenuItem onClick={openHideOrderPropertiesDialog}>
        表示項目を選択
      </MenuItem>
      <MenuItem onClick={handleGroupingDialogOpen}>
        {useGrouping ? 'まとめを解除する' : '案件をまとめる'}
      </MenuItem>
    </StyledMenu>
  ), [displaySettingsAnchorEl, handleGroupingDialogOpen, onClickCloseDisplaySettingsMenu, displaySettingsMenuOpen, openHideOrderPropertiesDialog, useGrouping]);

  const [displayOnlySelectedAnchorEl, setDisplayOnlySelectedAnchorEl] = useState<null | HTMLElement>(null);
  const displayOnlySelectedMenuOpen = Boolean(displayOnlySelectedAnchorEl);
  const onClickOpenDisplayOnlySelectedMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDisplayOnlySelectedAnchorEl(event.currentTarget);
  }, []);
  const handleDisplayOnlySelectedMenuClose = useCallback(() => {
    setDisplayOnlySelectedAnchorEl(null);
  }, []);

  const displayOnlySelectedMenuMemo = useMemo(() => (
    <>
      <IconButton
        sx={{ p: 0, m: 0 }}
        onClick={onClickOpenDisplayOnlySelectedMenu}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <StyledMenu
        anchorEl={displayOnlySelectedAnchorEl}
        open={displayOnlySelectedMenuOpen}
        onClose={handleDisplayOnlySelectedMenuClose}
      >
        <MenuItem
          onClick={displayOnlySelectedButtonOnClick}
          disableRipple
          sx={{ p: 0, m: 0, }}
        >
          {displayOnlySelected ? 'すべて表示する' : '選択中のみ表示する'}
        </MenuItem>
      </StyledMenu>
    </>
  ), [displayOnlySelected, displayOnlySelectedAnchorEl, displayOnlySelectedButtonOnClick, displayOnlySelectedMenuOpen, handleDisplayOnlySelectedMenuClose, onClickOpenDisplayOnlySelectedMenu]);

  const topToolbarMemo = useMemo(() => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ pt: 1 }}
    >
      <Stack direction="row">
        <Stack direction="row">
          {checkboxWithBadgeMemo}
          {selectedIdsLength > 0 && displayOnlySelectedMenuMemo}
        </Stack>
        {selectedIdsLength > 0 && deleteOrderIconMemo}
      </Stack>
      <Stack>
        <IconButton
          sx={{ padding: '3px' }}
          onClick={onClickOpenDisplaySettingsMenu}
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
      {displaySettingsMemo}
    </Stack>
  ), [checkboxWithBadgeMemo, deleteOrderIconMemo, displayOnlySelectedMenuMemo, displaySettingsMemo, onClickOpenDisplaySettingsMenu, selectedIdsLength]);

  const statisticsWrapperMemo = useMemo(() => (
    <Stack
      sx={{
        position: 'relative',
        px: 1,
      }}
    >
      {planningOrderStatisticsPresenterMemo}
    </Stack>
  ), [planningOrderStatisticsPresenterMemo]);

  const searchBarMemo = useMemo(() => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {keywordSearchTextMemo}
      {resetSearchButtonMemo}
      {detailSearchIconMemo}
    </Stack>
  ), [detailSearchIconMemo, keywordSearchTextMemo, resetSearchButtonMemo]);

  const ordersHeaderMemo = useMemo(() => (
    <Stack
      direction="column"
      alignItems="flex-start"
      sx={{
        bgcolor: theme.colors.alpha.trueWhite[100],
        position: 'fixed',
        zIndex: 1010,
        width: unallocatedDrawerWidth,
      }}
    >
      <Paper
        square
        sx={{
          width: '100%',
          py: 0.5,
          px: 0.5,
        }}
      >
        {statisticsWrapperMemo}
        {searchBarMemo}
        {topToolbarMemo}
      </Paper>
    </Stack>
  ), [theme.colors.alpha.trueWhite, unallocatedDrawerWidth, statisticsWrapperMemo, searchBarMemo, topToolbarMemo]);

  const orderFormDialogMemo = useMemo(() => (
    <OrderFormPresenter
      dialogIsOpen={dialogIsOpen}
      dialogOnClose={dialogOnClose}
      orderId={toEditOrderId}
      startOn={startOn}
      endOn={endOn}
      onClose={dialogOnCloseWithResetAllocateHistories}
      onClickSplit={onClickSplit}
    />
  ), [dialogIsOpen, dialogOnClose, dialogOnCloseWithResetAllocateHistories, endOn, onClickSplit, startOn, toEditOrderId]);

  const orderSplitFormDialogMemo = useMemo(() => (
    <Dialog
      open={splitDialogIsOpen}
      maxWidth="md"
      fullWidth
      onClose={splitDialogOnClose}
    >
      <DialogContent>
        <OrderSplitFormPresenter
          startOn={startOn}
          endOn={endOn}
          onClose={splitDialogOnCloseWithResetAllocateHistories}
          onSubmit={onSplitDialogSubmit}
          orderId={toEditOrderId}
        />
      </DialogContent>
    </Dialog>
  ), [endOn, onSplitDialogSubmit, splitDialogIsOpen, splitDialogOnClose, splitDialogOnCloseWithResetAllocateHistories, startOn, toEditOrderId]);

  const displayFieldCustomDialogMemo = useMemo(() => (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={hideOrderPropertiesDialogIsOpen}
      onClose={closeHideOrderPropertiesDialog}
    >
      <DialogTitle>
        表示項目を選択
      </DialogTitle>
      <DialogContent>
        <Stack
          divider={<Divider />}
          spacing={1}
        >
          {
            [
              {
                groupTitle: '設定された項目',
                properties: customInputFields.map((it) => ({
                  key: it,
                  value: it,
                }))
              },
              ...hideOrderPropertyJaObjects
            ].filter((it) => it.properties.length)
              .map(({ groupTitle, properties }) => (
                <Stack
                  key={[
                    'hideOrderPropertiesDialogGroup',
                    groupTitle
                  ].join('-')}
                >
                  <Typography>
                    {groupTitle}
                  </Typography>
                  {
                    arrayUtil.sliceByNumber(properties, 4).map((slicedProperites, index) => (
                      <Stack
                        direction="row"
                        key={['hideOrderPropertiesDialogGroup', groupTitle, index].join('-')}
                      >
                        {
                          slicedProperites.map(({ key, value }) => (
                            <FormControlLabel
                              key={[
                                'hideOrderPropertiesDialog',
                                'FormControlLabel',
                                groupTitle,
                                key,
                              ].join('-')}
                              control={(
                                <Checkbox
                                  checked={!hideOrderProperties.includes(key)}
                                  onChange={(event) => {
                                    hideOrderPropertiesDialogSelectBoxOnChange(event, key);
                                  }}
                                />
                              )}
                              label={value}
                            />
                          ))
                        }
                      </Stack>
                    ))
                  }
                </Stack>
              ))
          }
        </Stack>
      </DialogContent>
    </Dialog>
  ), [closeHideOrderPropertiesDialog, customInputFields, hideOrderProperties, hideOrderPropertiesDialogIsOpen, hideOrderPropertiesDialogSelectBoxOnChange, hideOrderPropertyJaObjects]);

  return (
    <>
      <Scrollbar>
        {ordersHeaderMemo}
        {ordersMemo}
      </Scrollbar>
      {orderFormDialogMemo}
      {orderSplitFormDialogMemo}
      {displayFieldCustomDialogMemo}
      {groupingConditionDialogMemo}
      <Dialog
        open={searchDialogIsOpen}
        onClose={closeSearchDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          未割り当て案件の検索
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            pt={1}
          >
            {
              searchConditions
                .map((obj, idx) => (
                  <Stack
                    key={[
                      'searchOrderProperty',
                      'condition',
                      idx,
                      ...Object.values(obj)
                    ].join('-')}
                    direction="row"
                    gap={1}
                  >
                    {
                      idx !== 0 && (
                        <Select
                          value={obj.logicalOperator}
                          onChange={(event) => {
                            updateLogicalOperator(idx, event.target.value as LogicalOperatorVo);
                          }}
                          size="small"
                          variant="standard"
                        >
                          {
                            logicalOperators.map((it, i) => (
                              <MenuItem
                                key={[
                                  'searchOrderProperty',
                                  'logicalOperator',
                                  'MenuItem',
                                  it,
                                  i,
                                ].join('-')}
                                value={it}
                              >
                                {it}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      )
                    }
                    <FormControl>
                      <InputLabel>検索対象</InputLabel>
                      <Select
                        value={obj.key}
                        onChange={(event) => {
                          updateSearchKey(idx, event.target.value);
                        }}
                        sx={{
                          minWidth: 120
                        }}
                        size="small"
                        variant="standard"
                      >
                        {
                          orderProperties
                            .map((property, i) => (
                              <MenuItem
                                value={property.key}
                                key={[
                                  'searchOrderProperty',
                                  'key',
                                  'MenuItem',
                                  property.key,
                                  i,
                                ].join('-')}
                              >
                                {property.value}
                              </MenuItem>
                            ))
                        }
                      </Select>
                    </FormControl>
                    <FormControl>
                      <InputLabel>検索方法</InputLabel>
                      <Select
                        value={obj.operator}
                        disabled={!orderProperties.find((it) => it.key === obj.key)?.type}
                        onChange={(event) => {
                          updateSearchOperator(idx, event.target.value as SearchOperatorVo);
                        }}
                        sx={{
                          minWidth: 100
                        }}
                        size="small"
                        variant="standard"
                      >
                        {
                          orderProperties.find((it) => it.key === obj.key)?.type
                          && searchOperator[orderProperties.find((it) => it.key === obj.key)?.type]
                            .map((it, i) => (
                              <MenuItem
                                value={it}
                                key={[
                                  'searchOrderProperty',
                                  'operator',
                                  'MenuItem',
                                  it,
                                  i,
                                ].join('-')}
                              >
                                {it}
                              </MenuItem>
                            ))
                        }
                      </Select>
                    </FormControl>
                    <Stack
                      flexGrow={1}
                    >
                      <TextField
                        key={[
                          'searchOrderProperty',
                          'value',
                          idx,
                          ...Object.values(obj),
                        ].join('-')}
                        value={currentValue?.idx === idx ? currentValue.value : obj.value}
                        label="検索する値"
                        disabled={
                          orderProperties.find((it) => it.key === obj.key)?.type === 'boolean'
                          || !orderProperties.find((it) => it.key === obj.key)?.type
                        }
                        type={orderProperties.find((it) => it.key === obj.key)?.type}
                        onChange={(event) => {
                          setCurrentValue({
                            idx,
                            value: event.target.value,
                          });
                        }}
                        onBlur={(event) => {
                          updateSearchValue(idx, event.target.value);
                        }}
                        size="small"
                        variant="standard"
                        fullWidth
                      />
                    </Stack>
                    {(idx !== 0) && (
                      <IconButton
                        color="error"
                        onClick={() => {
                          deleteSearchConditions(idx);
                        }}
                      >
                        <RemoveCircleRoundedIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            gap={0.5}
          >

            <Select
              sx={{
                minWidth: 150,
                maxWidth: 150,
              }}
              size="small"
              value={selectedSavedSearchConditionName}
              onChange={(event) => {
                updateSelectedSavedSearchConditionName(event.target.value);
              }}
              disabled={!(savedSearchConditions.length + companySearchConditions.length)}
              renderValue={(selected: string) => selected.split(companySearchConditionIdPrefix)[0]}
            >
              {
                companySearchConditions.map((it) => (
                  <MenuItem
                    key={[
                      'savedSearchCondition',
                      'server',
                      'MenuItem',
                      it.name
                    ].join('-')}
                    value={`${it.name} (${it.companyName})${companySearchConditionIdPrefix}${it.id}`}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      {`${it.name} (${it.companyName})`}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => {
                            deleteCompanySearchConditionOnClick(it.id);
                          }}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))
              }
              {
                savedSearchConditions.map((it) => (
                  <MenuItem
                    key={[
                      'savedSearchCondition',
                      'MenuItem',
                      it.name
                    ].join('-')}
                    value={it.name}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      {it.name}
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Tooltip title="検索条件をアカウント内で共有する">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              uploadSearchConditionButtonOnClick(it);
                            }}
                          >
                            <CloudUploadRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => {
                            removeSavedSearchConditionButtonOnClick(it.name);
                          }}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))
              }
            </Select>
            <LoadingButton
              loading={isLoading}
              startIcon={<YoutubeSearchedForRoundedIcon />}
              size="small"
              disabled={!selectedSavedSearchConditionName || !(savedSearchConditions.length + companySearchConditions.length)}
              onClick={restoreSavedSearchConditionButtonOnClick}
            >
              読み込む
            </LoadingButton>
          </Stack>
          <LoadingButton
            loading={isLoading}
            disabled={
              searchConditions.length === 0
            }
            onClick={openSaveNewSearchConditionDialog}
            startIcon={<StarRoundedIcon />}
            size="small"
          >
            条件を保存
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            startIcon={<AddCircleRoundedIcon />}
            onClick={addSearchConditions}
            disabled={
              searchConditions.length === 0
            }
            size="small"
          >
            条件を追加
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            startIcon={<FindInPageRoundedIcon />}
            disabled={
              searchConditions.length === 0
            }
            size="small"
            onClick={searchButtonOnClick}
          >
            検索
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={saveNewSearchConditionDialogIsOpen}
      >
        <DialogTitle>
          名前をつけて検索条件を保存する
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={newSearchConditionName}
            onChange={(event) => {
              updateNewSearchConditionName(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            onClick={closeSaveNewSearchConditionDialog}
            color="secondary"
            size="small"
          >
            キャンセル
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            disabled={
              !Object.values([...searchConditions].slice(-1)[0]).every((maybe) => maybe)
            }
            onClick={saveNewSearchCondition}
            size="small"
          >
            保存する
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OrdersPresenter;
